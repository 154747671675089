// @ts-ignore
import { Turbo } from "@hotwired/turbo-rails"
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  download() {
    const u = new URL(window.location.href)
    u.pathname = u.pathname + ".csv"
    Turbo.visit(u.href)
  }
}
