import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["xmlUseCredentials", "tabs"]
  readonly xmlUseCredentialsTarget!: HTMLInputElement
  readonly tabsTargets!: HTMLElement[]

  connect() {
    this.changeXmlAuth()
  }

  changeXmlAuth() {
    this.tabsTargets.forEach((element) => {
      this.xmlUseCredentialsTarget.checked ? element.classList.remove("hidden") : element.classList.add("hidden")
    })
  }
}