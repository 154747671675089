import { Controller } from "@hotwired/stimulus"
import { useIntersection } from "stimulus-use"

export default class extends Controller {
  static values = { selector: String }
  readonly selectorValue!: string
  
  options = {
    threshold: 1
  }

  connect() {
    useIntersection(this, this.options)
  }

  appear() {
    const header = document.querySelector(this.selectorValue)
    header?.classList.remove("compact")
  }

  disappear() {
    const header = document.querySelector(this.selectorValue)
    header?.classList.add("compact")
  }
}
