import { Controller } from "@hotwired/stimulus"

export default class ToggleHiddenController extends Controller {
  static targets = ["toggleable"]
  readonly toggleableTargets!: HTMLElement[]

  toggle() {
    this.toggleableTargets.forEach((element: HTMLElement) => {
      if (element.classList.contains("expandable__wrapper")) {
        const invisible = element.classList.contains("invisible")
        // hide last, show first to show the animation
        // assumes that both .expandable__wrapper and .expandable have data-toggle-hidden-target="toggleable"
        if (invisible) {
          element.classList.remove("invisible")
        } else {
          element.addEventListener("transitionend", () => {
            element.classList.add("invisible")
          }, { once: true })
        }
        element.style.gridTemplateRows = invisible ? "1fr" : "0fr"
      } else {
        element.classList.toggle("hidden")
      }
    })

    this.element.classList.toggle("toggleable-hidden")
  }
}
