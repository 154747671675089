import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["observee"]
  readonly observeeTargets!: HTMLElement[]

  observeeTargetConnected(element: HTMLElement) {
    const callback = (mutationsList: MutationRecord[]) => {
      // Allow there to be more than one target in views
      element.dataset.eventTarget?.trim().split(" ").forEach( target => {
        const eventTarget = document.querySelector(target || "") || element
        const event = new CustomEvent("classlist-changed");
        eventTarget.dispatchEvent(event);
      })
    }

    const observer = new MutationObserver(callback);
    const config = { attributes: true, attributeFilter: ['class'] };
    observer.observe(element, config);
  }
}