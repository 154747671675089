import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["firmwareVersion", "termsCheckbox", "weeklySelection"]
  readonly firmwareVersionTargets!: HTMLElement[]
  readonly termsCheckboxTarget!: HTMLInputElement
  readonly weeklySelectionTarget!: HTMLInputElement
  declare readonly hasWeeklySelectionTarget: boolean

  firmware_clicked(event: Event) {
    const target = event.target as HTMLElement
    const listItem = target.closest(".firmware-list-item-wrapper") as HTMLElement
    if (!listItem?.classList.contains("--available-firmware") && !listItem?.classList.contains("--selected-firmware")) return

    const currentlySelected = listItem.classList.contains("--selected-firmware")

    for (const item of this.firmwareVersionTargets) {
      if (item == listItem) {
        this.setFirmwareSelected(listItem, !currentlySelected)
      } else if (!item.classList.contains("--current-firmware") && !item.classList.contains("--disabled-firmware")) {
        this.setFirmwareSelected(item, false)
      }
    }
  }

  setFirmwareSelected(element: HTMLElement, selected: boolean) {
    element.classList.remove(selected ? "--available-firmware" : "--selected-firmware")
    element.classList.add(selected ?  "--selected-firmware" : "--available-firmware")

    if (selected) {
      const selectedFirmwareElement = document.querySelector("#firmware_upgrade_network_gateway_firmware_id") as HTMLInputElement
      selectedFirmwareElement.value = element.id
    }
  }

  upgrade(event: Event) {
    const form = document.getElementById("maintenance_settings_form") as HTMLFormElement

    if (!this.termsCheckboxTarget.checked) {
      event.preventDefault()
      this.termsCheckboxTarget.parentElement?.classList.add("bg-red-200")
      return
    } else {
      this.termsCheckboxTarget.parentElement?.classList.remove("bg-red-200")
    }

    if (document.querySelectorAll('input[type=radio]').length > 0) {
      const day = document.querySelector('input[type=radio]:checked')
      const timePicker = document.querySelector('#start') as HTMLInputElement

      if (!day && timePicker.value == "") {
        event.preventDefault()
        const submit = document.querySelector("#maintenance_settings_submit") as HTMLInputElement
        const result = confirm(submit.dataset["confirmMessage"])
        if (result) {
          form.submit()
        }
      }
    }
  }

  clearTime(event: Event) {
    event.preventDefault()
    const time = document.querySelector('#start') as HTMLInputElement
    if (time) time.value = ""
  }
}
