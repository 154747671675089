// @ts-ignore
import { Turbo } from "@hotwired/turbo-rails"
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["basePage", "page", "pagesWrapper", "backButton", "pageHistory"]
    readonly pagesWrapperTarget!: HTMLElement
    readonly basePageTarget!: HTMLElement
    readonly pageTargets!: HTMLElement[]
    readonly backButtonTarget!: HTMLElement
    readonly pageHistoryTarget!: HTMLInputElement
    declare readonly hasBasePageTarget: boolean
    declare readonly hasPageTarget: boolean

    connect() {
        document.body.dataset.controller = "modal"
        document.body.dataset.action = "click->modal#domClicked"
        this.addContentObservers()
    }

    domClicked() {
        this.hideAllModals()
    }

    addContentObservers() {
        for (let modalContent of document.querySelectorAll(".modal-content")) {
            if (modalContent.classList.contains("observed")) continue

            let trigger = modalContent.closest(".modal-trigger")
            if (trigger && !this.isTooltip(trigger as HTMLElement)) this.addContentObserver(modalContent as HTMLElement)
        }
    }

    addContentObserver(element: HTMLElement) {
        let that = this
        var observer = new MutationObserver(function (mutations) {
            mutations.forEach(function (mutation) {
                for (var i = 0; i < mutation.addedNodes.length; i++)
                    that.contentAdded(mutation.addedNodes[i])
            })
        });
        observer.observe(element, { childList: true, subtree: true });
        element.classList.add("observed")
    }

    createDiv(className?: string) {
        const div = document.createElement("div")
        if (className) div.className = className!
        return div
    }

    modalClicked(event: Event) {
        const target = event.target as HTMLElement
        const trigger = target.classList.contains("modal-trigger--loaded") ? target : target.closest(".modal-trigger--loaded") as HTMLElement

        if (trigger) {
            if (this.isTooltip(trigger) || !target.closest(".modal")) this.findAndToggleModal(target)
        }
    }

    hideAllModals(except?: HTMLElement) {
        for (let modal of document.querySelectorAll(".modal")) {
            if (except) {
                if (modal != except) this.hideModal(modal as HTMLElement)
            } else {
                this.hideModal(modal as HTMLElement)
            }
        }
    }

    findAndToggleModal(element: HTMLElement) {
        let modalTrigger = element.closest(".modal-trigger") as HTMLElement
        let modal = modalTrigger?.querySelector(".modal") as HTMLElement
        if (modal) {
            if (modalTrigger.dataset.showCondition) {
                if (modalTrigger.dataset.showCondition !== "false") {
                    this.toggleModal(modal)
                    if (!this.isTooltip(modalTrigger)) this.hideAllModals(modal)
                }
            } else {
                this.toggleModal(modal)
                if (!this.isTooltip(modalTrigger)) this.hideAllModals(modal)
            }
        }
    }

    contentAdded(node: Node) {
        const content = node as HTMLElement
        if ("" == content.textContent?.trim()) return
        if (typeof content.closest !== "function") return
        if (content.closest(".modal-wrapper--loaded")) {
            // Modal is already loaded, we don't need to toggle/place it again
            return
        }

        const modal = content.parentElement!.parentElement!

        this.toggleModal(modal)
        if (!content.style) return

        this.resizeAndPlace(modal)
        this.convertToDiv(modal)
    }

    convertToDiv(modal: HTMLElement) {
        const trigger = modal.closest(".modal-trigger")! as HTMLElement
        const newTrigger = this.createDiv(trigger.className)
        for (let data in trigger.dataset) {
            newTrigger.dataset[data] = trigger.dataset[data]
        }
        newTrigger.innerHTML = trigger.innerHTML
        trigger.parentNode?.replaceChild(newTrigger, trigger)
    }

    resizeAndPlace(modal: HTMLElement) {
        const trigger = modal.closest(".modal-trigger")! as HTMLElement
        const content = modal.querySelector(".modal-content")! as HTMLElement
        if (!content) return

        modal.parentElement!.classList.add("modal-wrapper--loaded")
        trigger.classList.add("modal-trigger--loaded")

        modal!.style.width = content.style.width

        const anchorMidpoint = trigger.getBoundingClientRect().width / 2
        const modalMidpoint = modal.getBoundingClientRect().width / 2
        let leftStyle = (anchorMidpoint - modalMidpoint)
        modal.style.left = leftStyle.toString() + "px"
        const arrow = modal.querySelector(".arrow-wrapper") as HTMLElement
        if (modal.classList.contains("modal--float-left")) {
            arrow.style.marginLeft = (anchorMidpoint - 20) + "px";
        } else if (modal.classList.contains("modal--float-right")) {
            arrow.style.marginRight = (anchorMidpoint - 20) + "px";
        }
    }

    hidePressed(event: Event) {
        event.preventDefault()
        event.stopPropagation()
        const target = event.target as HTMLElement
        const tooltip = target.closest(".tooltip")
        this.hideModal(tooltip != null ? tooltip.querySelector(".modal")! : target.closest(".modal")!)
    }

    toggleModal(modal: HTMLElement) {
        modal.parentElement!.classList.toggle("hidden")
        if (!modal.parentElement!.classList.contains("hidden")) this.resizeAndPlace(modal)
    }

    hideModal(modal: HTMLElement) {
        if (!modal.classList.contains("hidden")) {
            modal.parentElement!.classList.add("hidden")
        }
    }

    isTooltip(modal: HTMLElement) {
        return modal.classList.contains("tooltip")
    }

    pageLinkPressed(event: Event) {
        if (!this.canMovePage()) {
            let error = ""
            if (!this.hasBasePageTarget) error = "Base page not defined."
            if (!this.hasPageTarget) error = "No page defined."
            console.log(`${error} Make sure to define a base and other pages.`)
            return
        }

        const target = event.target as HTMLElement
        const link = target.closest(".--modal-page-link") as HTMLElement
        const currentPage = this.getCurrentPage()!
        let nextPage = this.getPageFromId(`modal-page-${link.dataset.target}`)
        if (currentPage && nextPage) this.switchPage(currentPage, nextPage)
    }

    pageBackPressed() {
        const currentPage = this.getCurrentPage()
        const previousPage = this.getPreviousPage()

        if (currentPage && previousPage) this.switchPage(currentPage, previousPage)
    }

    switchPage(fromPage: HTMLElement, toPage: HTMLElement) {
        toPage.classList.remove("hidden")

        if (this.getPreviousPage()?.id != toPage.id) {
            this.addPageVisited(toPage.id);
            this.movePageWrapper(-fromPage.clientWidth - 10)
        } else {
            this.removePageVisited(fromPage.id);
            this.movePageWrapper(toPage.clientWidth + 10)
        }

        (this.getCurrentPage() == this.basePageTarget) ? this.backButtonTarget.classList.add("hidden") : this.backButtonTarget.classList.remove("hidden")

        this.pagesWrapperTarget.parentElement!.style.width = toPage.clientWidth + "px"
    }

    movePageWrapper(offset: number) {
        let currentOffset = this.pagesWrapperTarget.style.left ? parseInt(this.pagesWrapperTarget.style.left.split("px")[0]) : 0
        offset = offset + currentOffset
        this.pagesWrapperTarget.style.left = `${offset}px`
    }

    getCurrentPage() {
        return this.getPage(0)!
    }

    getPreviousPage() {
        return this.getPage(1)
    }

    getPage(distanceFromEnd: number) {
        const history = this.getPageHistory()
        let id: string
        if (history) {
            id = history.split(",").slice(-1 - distanceFromEnd)[0]
            return this.getPageFromId(id)
        } else {
            const base = this.basePageTarget.id
            this.addPageVisited(base)
            return this.basePageTarget
        }
    }

    getPageFromId(id: string) {
        if (!this.hasBasePageTarget && !this.hasPageTarget) return
        if (id == this.basePageTarget.id) return this.basePageTarget
        for (let page of this.pageTargets) {
            if (page.id == id) return page
        }
    }

    getPageHistory() {
        return this.pageHistoryTarget.value
    }

    addPageVisited(pageName: string) {
        const history = this.getPageHistory()
        this.pageHistoryTarget.value = history ? `${history},${pageName}` : pageName
    }

    removePageVisited(pageName: string) {
        const history = this.getPageHistory()
        if (history) {
            let newHistory = history.split(",").filter((item) => item !== pageName)
            this.pageHistoryTarget.value = newHistory.join(",")
        }
    }

    canMovePage() {
        return this.hasBasePageTarget && this.hasPageTarget
    }

    endSlide() {
        // If left == 0px, we're at the base page level, so hide all child pages
        if (this.pagesWrapperTarget.style.left == "0px") {
            if (this.getCurrentPage() == this.basePageTarget) {
                this.hidePages()
            } else {
                this.hideChildPages(this.getCurrentPage())
            }
        }

        // Otherwise, we need to hide the page to the right
        for (const shownPage of document.querySelectorAll(".modal-page:not(.hidden)")) {
            if (shownPage != this.basePageTarget && shownPage == this.getCurrentPage().nextElementSibling) {
                shownPage.classList.add("hidden")
            }
        }
    }

    hidePages() {
        this.pageTargets.forEach((page) => {
            page.classList.add("hidden")
        })
    }

    hideChildPages(page: HTMLElement) {
        for (let child of page.querySelectorAll(".modal-page")) {
            child.classList.add("hidden")
        }
    }
}
