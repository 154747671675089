import { Controller } from "@hotwired/stimulus"
import { useIntersection } from "stimulus-use"

export default class extends Controller {
  options = {
    threshold: 1
  }

  connect() {
    useIntersection(this, this.options)
  }

  appear() {
    const link = this.element as HTMLAnchorElement
    const page = link.dataset.page
    if (page) {
      const u = new URL(window.location.href)
      u.searchParams.set("page", page)
      link.href = u.href
      link.click()
    }
  }
}
