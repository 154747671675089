import PopoverController from "./popover_controller"

export default class extends PopoverController {
  triggerClass = "tooltip__trigger"
  arrowShift = "-3px"

  contentTarget() {
    return document.getElementById("tooltip") as HTMLElement
  }

  hasBackdrop() {
    return false
  }

  templateId(e: Event) {
    return (e.currentTarget as HTMLElement).dataset?.tooltipTemplateId
  }
}
