import { Controller } from "@hotwired/stimulus"
import { fetchAndReplaceHistory, toggleItemInFilterSet } from "../../../../../nc/app/javascript/nc/utils"

export default class extends Controller {
  static values = { filter: String }
  readonly filterValue!: string

  static targets = ["panel"]
  readonly panelTarget!: HTMLElement

  filter() {
    const currentlyOn = this.panelTarget.getAttribute("aria-selected") === "true"
    const u = new URL(window.location.href)
    if (this.filterValue != "failover") {
      toggleItemInFilterSet(u, "status", this.filterValue, !currentlyOn)

      if (currentlyOn) {
        this.panelTarget.removeAttribute("aria-selected")
      } else {
        this.panelTarget.setAttribute("aria-selected", "true")
      }
    } else {
      if (currentlyOn) {
        u.searchParams.delete(this.filterValue)
        this.panelTarget.removeAttribute("aria-selected")
      } else {
        u.searchParams.set(this.filterValue, "true")
        this.panelTarget.setAttribute("aria-selected", "true")
      }
    }
    u.searchParams.delete("page")

    fetchAndReplaceHistory(u.href)
  }
}
