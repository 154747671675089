import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["body", "section"]
  static values = {closedByDefault: Boolean}
  readonly bodyTargets!: HTMLElement[]
  readonly sectionTargets!: HTMLElement[]
  readonly closedByDefaultValue!: boolean

  connect() {
    if(!this.closedByDefaultValue) {
      if (this.bodyTargets.length > 0 ) {
        this.bodyTargets[0].classList.add("visible")
      }
      document.querySelectorAll(".accordion").forEach((target: Element) => {
        target.classList.add("section--expanded")
      })
    }
  }

  expand(event: Event) {
    event.preventDefault()
    const buttonTarget = event.currentTarget as HTMLElement
    const sectionTarget = buttonTarget.closest(".accordion")
    if (sectionTarget) {
      sectionTarget.classList.toggle("section--expanded")
      this.sectionTargets.forEach((element: Element) => {
        if (element != sectionTarget) {
            element.classList.remove("section--expanded")
        }
      })

      const bodyTarget = sectionTarget.querySelector(".accordion__body")
      this.bodyTargets.forEach((element: Element) => {
        if (element != bodyTarget) {
          element.classList.remove("visible")
        }
      })
      if (bodyTarget) {
        bodyTarget.classList.toggle("visible")
      }
    }
  }
}
