import { Controller } from "@hotwired/stimulus"
import { fetchAndReplaceHistory } from "../utils"

export default class extends Controller {
  static targets = ["search"]
  readonly searchTarget!: HTMLFormElement

  timeout: number | undefined

  search() {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      const href = (this.searchTarget.dataset.url ?? window.location.href).split(".html")[0]
      const u = new URL(href)
      u.searchParams.delete("s")
      if (this.searchTarget.value.length > 0) {
        u.searchParams.set("s", this.searchTarget.value)
      }
      u.searchParams.delete("page")

      fetchAndReplaceHistory(u.href)
    }, 400)
  }
}
