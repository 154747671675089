import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["result"]
  readonly resultTarget!: HTMLElement


  connect(){
    let searchDiv = document.querySelector("div.search_parent") as HTMLInputElement
    let removeDiv = document.querySelector("div.parent") as HTMLInputElement
    
    if (searchDiv?.querySelector("input")?.value && removeDiv?.querySelector("input")?.value){
      searchDiv.classList.remove("hidden")
    } else if (removeDiv?.querySelector("input")?.value) {
      removeDiv.classList.remove("hidden")
    } else {
      searchDiv.classList.remove("hidden")
    }

  }

  removeParent(event: Event) {
    event.preventDefault()
    let input = document.querySelector("#parent_organization") as HTMLInputElement
    input.removeAttribute("disabled")
    input.setAttribute("value", "");

    let removeDiv = document.querySelector("div.parent") as HTMLInputElement
    let searchDiv = document.querySelector("div.search_parent") as HTMLInputElement
    removeDiv.classList.add("hidden")
    searchDiv.classList.remove("hidden") 
  }

  setParent(event: Event){
    const target = event.target as any
    let textField = document.querySelector("div.search_parent > input") as HTMLFormElement
    textField.value = target.innerHTML.trim()

    let inputField = document.querySelector("div.parent > input") as HTMLFormElement
    inputField.removeAttribute("disabled")
    inputField.value = target.getAttribute('data-organization-result-value')
    this.resultTarget.classList.add("hidden")
  }
}