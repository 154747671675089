// @ts-ignore
import { Turbo } from "@hotwired/turbo-rails"
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  sort(e: Event) {
    const header = e.currentTarget as HTMLElement
    const field = header.dataset.field
    if (!field) {
      return
    }
    const u = new URL(window.location.href)
    const currentSort = u.searchParams.get("sort")
    const currentDir = u.searchParams.get("dir") || "asc"

    if (currentSort !== field) {
      u.searchParams.set("sort", field)
      u.searchParams.delete("dir")
    } else {
      u.searchParams.delete("dir")
      if (currentDir === "asc") {
        u.searchParams.set("dir", "desc")
      }
    }

    const el = this.element as HTMLElement
    if (el.dataset.turboStream) {
      fetch(u.href, { headers: { Accept: "text/vnd.turbo-stream.html" } })
        .then((response) => response.text())
        .then((html) => Turbo.renderStreamMessage(html))

      history.replaceState(null, "", u.href)
    } else {
      window.location.href = u.href
    }
  }
}
