import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["tableBody", "dateYear", "wrapper", "datesInput", "backBtn", "forwardBtn"]
    readonly tableBodyTarget!: HTMLElement
    readonly dateYearTarget!: HTMLElement
    readonly wrapperTarget!: HTMLElement
    readonly datesInputTarget!: HTMLInputElement
    readonly backBtnTarget!: HTMLInputElement
    readonly forwardBtnTarget!: HTMLInputElement

    currentDate: Date = new Date()
    selectedDates: Date[] = []
    firstCell?: HTMLElement
    allowPastDates = true
    pastDateLimitDays: number | undefined
    allowMultipleDates = true
    allowFutureDates = true
    notBefore?: HTMLInputElement
    notAfter?: HTMLInputElement
    customInput?: HTMLInputElement

    connect() {
        const wrapperData = this.wrapperTarget.dataset
        this.allowPastDates = wrapperData.allowPastDates == "true"
        this.pastDateLimitDays = wrapperData.pastDateLimitDays && Number(wrapperData.pastDateLimitDays) ? Number(wrapperData.pastDateLimitDays) : undefined
        this.allowFutureDates = wrapperData.allowFutureDates == "true"
        this.allowMultipleDates = wrapperData.allowMultipleDates == "true"

        const customInputId = wrapperData.inputId
        this.customInput = customInputId ? document.querySelector(`#${customInputId}`)! : undefined
        if (customInputId && this.customInput == undefined) {
            throw new Error(`Custom input ID of #${customInputId} has been defined but could not be found on the dom.`)
        }
        const notBeforeId = wrapperData.notBeforeId
        this.notBefore = notBeforeId ? document.querySelector(`#${notBeforeId}`)! : undefined
        if (notBeforeId && this.notBefore == undefined) {
            throw new Error(`Not before ID of #${notBeforeId} has been defined but could not be found on the dom.`)
        }
        const notAfterId = wrapperData.notAfterId
        this.notAfter = notAfterId ? document.querySelector(`#${notAfterId}`)! : undefined
        if (notAfterId && this.notAfter == undefined) {
            throw new Error(`Not after ID of #${notAfterId} has been defined but could not be found on the dom.`)
        }

        this.loadSelectedDatesFromInput()
        let selectedDate = this.currentDate
        if (this.selectedDates.length > 0) this.currentDate = this.selectedDates[0]
        if (!this.allowPastDates && this.currentDate < selectedDate) this.currentDate = selectedDate
        this.renderDates()
    }

    redraw() {
        this.renderDates()
    }

    renderDates() {
        this.loadSelectedDatesFromInput()

        const lastDay = new Date(
            this.currentDate.getFullYear(),
            this.currentDate.getMonth() + 1,
            0
        );

        this.dateYearTarget.textContent = `${this.currentDate.toLocaleString("default", {
            month: "long"
        })} ${this.currentDate.getFullYear()}`;

        this.resetElements()

        let firstDay = new Date(
            this.currentDate.getFullYear(),
            this.currentDate.getMonth(),
            1
        ).getDay()

        for (let day = 1; day <= lastDay.getDate(); day++) {
            if (firstDay == 0) {
                this.populateThisMonthDay(day, 7);
            } else {
                this.populateThisMonthDay(day);
            }
        }

        this.fillRemainingDates()

        const today = new Date()
        const earlierOrEqualToThisMonth = today.getMonth() >= this.currentDate.getMonth() && today.getFullYear() >= this.currentDate.getFullYear()
        const greaterOrEqualToThisMonth = today.getMonth() <= this.currentDate.getMonth() && today.getFullYear() <= this.currentDate.getFullYear()
        this.backBtnTarget.classList.toggle("invisible", !this.allowPastDates && earlierOrEqualToThisMonth)
        this.forwardBtnTarget.classList.toggle("invisible", !this.allowFutureDates && greaterOrEqualToThisMonth)
    }

    resetElements() {
        this.removeClassFromAllDates('this-month')
        this.removeClassFromAllDates('selected')
    }

    removeClassFromAllDates(klass: string, except?: Element) {
        this.tableBodyTarget.querySelectorAll("td div.date-holder").forEach((el) => {
            if(el != except) el.classList.remove(klass);
        });
    }

    populateThisMonthDay(day: number, offset: number = 0) {
        const firstDate = this.getFirstDate()
        const thisDate = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), day);
        const valueToFind = (firstDate.getDay() + day + offset) - 1;
        const cell = this.tableBodyTarget.querySelector(`td[value='${valueToFind}'] div.date-holder`) as HTMLElement
        this.populateDayCell(cell, day, thisDate)
    }

    populateDayCell(cell: HTMLElement, day: number, newDate: Date) {
        cell.textContent = `${day}`

        cell.classList.toggle("current", newDate.toDateString() === new Date().toDateString())

        let disabled = false

        // Date boundaries are weird in JS/TS and if one date is at the very start of the day
        // or end the very end of the day (down to the second), it will be seen as the day before/after
        // So we need to watch out for that.

        newDate.setHours(12, 0, 0, 0)

        if (newDate.getMonth() !== this.currentDate.getMonth()) {
            disabled = true
        } else {
            if (day == 1) this.firstCell = cell
            cell.classList.add("this-month")
        }

        cell.dataset.date = newDate.toDateString()

        let endOfNewDate = new Date(newDate)
        endOfNewDate.setHours(23, 0, 0, 0)
        if (!this.allowPastDates && (endOfNewDate < new Date())) disabled = true
        if (this.pastDateLimitDays){
            let previousDateLimit = new Date()
            previousDateLimit.setHours(12, 0, 0, 0)
            previousDateLimit.setDate(previousDateLimit.getDate() - this.pastDateLimitDays)
            if (endOfNewDate < previousDateLimit) {
                disabled = true
            }
        }
        if (!this.allowFutureDates) {
            endOfNewDate.setHours(1, 0, 0, 0)
            if (endOfNewDate > new Date()) {
                disabled = true
            }
        }

        this.selectedDates.forEach((date) => {
            if (newDate.toDateString() === date.toDateString()) cell.classList.add("selected")
        })

        if (this.notBefore) {
            const notBeforeDate = new Date(this.notBefore.value)
            notBeforeDate.setHours(1,0,0,0)
            if (newDate < notBeforeDate) disabled = true
        }

        if (this.notAfter) {
            const notAfterDate = new Date(this.notAfter.value)
            notAfterDate.setHours(23,0,0,0)
            if (newDate > notAfterDate) disabled = true
        }

        cell.classList.toggle("disabled", disabled)
    }

    dateClicked(event: Event) {
        const cell = event.target as HTMLElement
        if (!cell.classList.contains("disabled")) {
            if (!this.allowMultipleDates) {
                this.removeClassFromAllDates('selected', cell)
            }
            cell.classList.toggle("selected")
            this.populateInput(cell.dataset.date!, cell.classList.contains("selected"))
        }
    }

    fillRemainingDates() {
        for (const cell of this.tableBodyTarget.querySelectorAll("td div.date-holder:not(.this-month)") as NodeListOf<HTMLElement>) {
            const firstDate = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), 1)
            const cellIdx = +this.getCellWrapper(cell)!.getAttribute("value")!
            const firstCellIdx = +this.getCellWrapper(this.firstCell!)!.getAttribute("value")!
            const diff = cellIdx - firstCellIdx
            firstDate.setDate(firstDate.getDate() + diff)
            this.populateDayCell(cell, firstDate.getDate(), firstDate)
        }
    }

    back() {
        this.currentDate.setMonth(this.currentDate.getMonth() - 1)
        this.renderDates()
    }

    forward() {
        const month = this.currentDate.getMonth();
        this.currentDate.setMonth(month + 1)

        // On a date overflow (e.g., November 31), it will roll over to the next month - therefore setting the date to the last day of the intended next month
        if (this.currentDate.getMonth() !== (month + 1) % 12) {
            this.currentDate.setDate(0);
          }
        this.renderDates()
    }

    getFirstDate() {
        return new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), 1)
    }

    populateInput(newDate: string, add: boolean) {
        this.loadSelectedDatesFromInput()
        let dates: string[] = this.selectedDates.map((date) => date.toDateString())

        if (add) {
            if (!this.allowMultipleDates) dates = []
            dates.push(newDate)
        } else {
            dates = dates.filter((el) => el !== newDate)
        }

        this.getFinalInputElement().value = dates.join(",")
        this.getFinalInputElement().dispatchEvent(new CustomEvent("change"))
    }

    loadSelectedDatesFromInput() {
        this.selectedDates = (this.getFinalInputElement().value.length == 0) ? [] : this.getFinalInputElement().value.split(",").map((date) => new Date(Date.parse(date)))
    }

    getCellWrapper(dateElement: HTMLElement) {
        return dateElement.closest(".cell-wrapper")
    }

    getFinalInputElement() {
        return (this.customInput == undefined) ? this.datesInputTarget! : this.customInput!;
    }

    reset() {
        this.getFinalInputElement().value = ""
        this.wrapperTarget.querySelectorAll("td div.selected").forEach((el) => {
            el.classList.remove("selected")
        })
    }
}
