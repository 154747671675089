import { Controller } from "@hotwired/stimulus"
import { fetchAndReplaceHistory, toggleItemInFilterSet } from "../utils"

export default class extends Controller {
  static values = { filter: String, secondaryFilter: String }
  static targets = ["filter"]
  readonly filterValue!: string
  readonly secondaryFilterValue!: string
  readonly filterTarget!: HTMLElement

  connect() {
    //  Set checkboxes according to current filter
    const u = new URL(window.location.href)
    const currentFilter = u.searchParams.get(this.filterValue) || ""
    const currentSet: string[] = currentFilter === "" ? [] : currentFilter.split(",")
    const checkboxes = this.element.querySelectorAll("[type='checkbox']")
    checkboxes.forEach((e) => {
      const c = e as HTMLInputElement
      const li = c.closest("li")
      const itemFilter = li?.dataset?.filter || "unset"
      const hasItem = currentSet.includes(itemFilter)
      c.checked = hasItem
      if (hasItem) {
        li?.setAttribute("aria-selected", "true")
      } else {
        li?.removeAttribute("aria-selected")
      }
    })
  } 

  filter(event: Event) {
    //  Get the data-filter attribute from the clicked item
    const target = event.currentTarget as HTMLElement
    const filterItem = target.dataset.filter!
    const filterType = target.dataset.filterType!
  
    const checkbox = target.querySelector("input[type='checkbox']") as HTMLInputElement
    checkbox.checked = !checkbox.checked
    const toggled = checkbox.checked
    if (toggled) {
      target.setAttribute("aria-selected", "true")
    } else {
      target.removeAttribute("aria-selected")
    }
  
    const u = new URL(window.location.href)
  
    toggleItemInFilterSet(u, filterType, filterItem, toggled)
    if(this.secondaryFilterValue){
      this.dispatch("filterSecondary", { target, detail : {toggled, u} })
    }
    fetchAndReplaceHistory(u.href)
  }

  deselectFilterItems(u: URL, filters: NodeListOf<Element>, filter: string) {
    const values = u.searchParams.get(filter)

    if (values) {
      let newValues = values.split(',')
  
      for (const filter of filters) {
        newValues = newValues.filter(value => value !== filter.getAttribute("data-filter"))
      }
  
      u.searchParams.set(filter, newValues.join(','))
  
      if(newValues.length === 0){
        u.searchParams.delete(filter)
      }
    }
  }

  clear() {
    this.clearCheckboxes(this.element)

    //  Remove the filter
    const u = new URL(window.location.href)
    const secondaryFilterItems = this.filterTarget.querySelectorAll('[data-parent-id]:not(.hidden)');
    if (secondaryFilterItems.length > 0) {
      // clear currently visible secondary filters
      this.deselectFilterItems(u, secondaryFilterItems, this.filterValue)
    } else {
      u.searchParams.delete(this.filterValue)
      toggleItemInFilterSet(u, "ignore", this.filterValue, false)
      this.clearSecondaryFilterItems(u)
    }  
    fetchAndReplaceHistory(u.href)
  }

  clearSecondaryFilterItems(u: URL){
    const secondaryFilter = document.querySelector(`[data-checkbox-filter-filter-value='${this.secondaryFilterValue}']`)
    if (secondaryFilter){
      u.searchParams.delete(this.secondaryFilterValue)
      toggleItemInFilterSet(u, "ignore", this.secondaryFilterValue, false)
      this.clearCheckboxes(secondaryFilter)
    }
  }

  clearCheckboxes(element: Element) {
    const checkboxes = element.querySelectorAll("[type='checkbox']")

    checkboxes.forEach((e) => {
      const c = e as HTMLInputElement
      const li = c.closest("li")
      c.checked = false
      li?.removeAttribute("aria-selected")
    })
  }
}
