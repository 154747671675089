import { Controller } from "@hotwired/stimulus"

export default class TabsController extends Controller {
  static targets = ["label", "content"]

  declare readonly contentTargets: HTMLElement[]
  declare readonly labelTargets: HTMLElement[]

  declare boundSelectTabFromAnchor: EventListener | undefined

  connect() {
    this.labelTargets.forEach((element) => {
      element.addEventListener("click", this.selectTabFromEvent.bind(this))
    })

    if ((this.element as HTMLElement).dataset.tabsAnchor) {
      this.selectTabFromAnchor()
      this.boundSelectTabFromAnchor = this.selectTabFromAnchor.bind(this)
      window.addEventListener("hashchange", this.boundSelectTabFromAnchor)
    }
  }

  disconnect() {
    if (this.boundSelectTabFromAnchor !== undefined) {
      window.removeEventListener("hashchange", this.boundSelectTabFromAnchor)
    }
  }

  selectTabFromAnchor() {
    const selectedTab = document.location.hash.replace("#", "")
    if (!selectedTab) return
    return this.selectTab(selectedTab)
  }

  selectTabFromEvent(event: Event) {
    const target = event.currentTarget as HTMLElement
    const selectedTab = target.dataset.tabLabel
    if (!selectedTab) return
    return this.selectTab(selectedTab)
  }

  selectTab(selectedTab: string) {
    this.contentTargets.forEach((element) => {
      if (element.dataset.content === selectedTab) {
        if (element.tagName === "TURBO-FRAME") (element as any).reload()
        element.classList.remove("hidden")
      } else {
        element.classList.add("hidden")
      }
    })

    this.labelTargets.forEach((element) => {
      element.dataset.tabLabel === selectedTab
        ? element.classList.add("tabs__tab--active")
        : element.classList.remove("tabs__tab--active")
    })

    if ((this.element as HTMLElement).dataset.tabsAnchor) {
      document.location.hash = `#${selectedTab}`
    }
  }
}
