import { Controller } from "@hotwired/stimulus"
import { fetchAndReplaceHistory } from "../utils"

export default class extends Controller {
  static targets = ["variableDateText", "todayText", "selectedDates", "backButton", "forwardButton", "remoteUrl", "calendarWrapper", "customParams"]
  variableDateTextTarget!: HTMLElement
  todayTextTarget!: HTMLElement
  selectedDatesTarget!: HTMLInputElement
  backButtonTarget!: HTMLElement
  forwardButtonTarget!: HTMLElement
  remoteUrlTarget!: HTMLElement
  calendarWrapperTarget!: HTMLElement
  customParamsTarget!: HTMLElement

  currentDate = new Date()
  dateOptions = { weekday: "long", month: 'long', day: 'numeric' } as const;

  connect() {
    this.currentDate = new Date(Date.parse(this.variableDateTextTarget.dataset.fullDate!))
    this.setVariableDateText()
  }

  back() {
    if (!this.backButtonTarget.classList.contains("inactive")) this.modifyDate(-1)
  }

  forward() {
    if (!this.backButtonTarget.classList.contains("inactive")) this.modifyDate(1)
  }

  datesChanged() {
    this.fetchDates(this.selectedDatesTarget.value.split(","))

    if (this.selectedDatesTarget.value.trim().length == 0) {
        this.todayPressed()
    } else {
        this.variableSelected()
        const dates = this.getDatesFromParams()
        if (dates.length == 1) {
            this.currentDate = dates[0]
        }
    }
  }

  modifyDate(daysModified: number) {
    this.currentDate.setDate(this.currentDate.getDate() + daysModified)
    this.fetchDates([this.currentDate.toDateString()])
    this.variableSelected()

    this.redrawCalendar()
  }

  redrawCalendar() {
    this.selectedDatesTarget.value = this.currentDate.toDateString()
    this.selectedDatesTarget.parentElement!.querySelector("div.calendar-datepicker")!.dispatchEvent(new CustomEvent("redraw"))
  }

  todayPressed() {
    this.variableDateTextTarget.classList.remove("selected")
    this.todayTextTarget.classList.add("selected")
    this.currentDate = new Date()
    this.fetchDates()
    this.redrawCalendar()
  }

  variableSelected() {
      this.variableDateTextTarget.classList.add("selected")
      this.todayTextTarget.classList.remove("selected")
  }

  fetchDates(dates?: string[]) {
    let href = this.remoteUrlTarget.dataset.url!.length == 0 ? window.location.href : this.remoteUrlTarget.dataset.url!
    href = href.split(".html")[0]
    const u = new URL(href)
    if (dates && dates[0].length != 0) {
        u.searchParams.set("selected_dates", dates.join(","))
    } else {
        u.searchParams.delete("selected_dates")
    }

    const customParams = this.customParamsTarget.dataset.customParams!.split(",")
    if (customParams[0] != '') {
      customParams.forEach((keyPair) => {
        const keyValue = keyPair.split("/")
        u.searchParams.set(keyValue[0], keyValue[1])
      })
    }

    fetchAndReplaceHistory(u.href)
    this.setVariableDateText()
  }

  setVariableDateText() {
    const dates = this.getDatesFromParams().map((date) => date.toLocaleDateString("en-US", this.dateOptions).replace(",", ""))
    if (dates.length > 0) {
        this.variableDateTextTarget.textContent = dates.length > 1 ? `${dates[0]} + ${dates.length - 1} more` : dates.join(", ")
        if (dates.length > 1) {
            this.hideArrows()
        } else {
            this.showArrows()
        }
      } else {
        this.showArrows()
        this.variableDateTextTarget.textContent = this.currentDate.toLocaleDateString("en-US", this.dateOptions).replace(",", "")
      }
  }

  getDatesFromParams() {
    const href = window.location.href.split(".html")[0]
    const u = new URL(href)
    const dateStrings = u.searchParams.get("selected_dates")?.trim().split(",")
    if (dateStrings && dateStrings[0].length != 0) {
        return dateStrings.map((dateString) => new Date(Date.parse(dateString)))
    } else {
        return []
    }
  }

  hideArrows() {
    const backParent = this.backButtonTarget.closest("[data-show-condition]")! as HTMLElement
    const frontParent = this.forwardButtonTarget.closest("[data-show-condition]")! as HTMLElement
    const tooltipAction = "click->tooltip#open mouseleave->tooltip#close"
    backParent.dataset.action = tooltipAction
    frontParent.dataset.action = tooltipAction
    backParent.dataset.showCondition = "true";
    frontParent.dataset.showCondition = "true"

    this.backButtonTarget.classList.add("inactive")
    this.forwardButtonTarget.classList.add("inactive");
}

  showArrows() {
    const backParent = this.backButtonTarget.closest("[data-show-condition]")! as HTMLElement
    const frontParent = this.forwardButtonTarget.closest("[data-show-condition]")! as HTMLElement
    backParent.dataset.action = ""
    frontParent.dataset.action = ""
    backParent.dataset.showCondition = "false";
    frontParent.dataset.showCondition = "false"
    
    this.backButtonTarget.classList.remove("inactive")
    this.forwardButtonTarget.classList.remove("inactive");
}
  
  variableDatesPressed(event: Event) {
    event.stopPropagation();
    (this.calendarWrapperTarget.querySelector(".calendar-trigger")! as HTMLElement).click()
  }
}
