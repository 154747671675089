// @ts-ignore
import { Turbo } from "@hotwired/turbo-rails"
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    fetchConnectivity(event: Event) {
        const selectedDates = document.querySelector("#selected_dates") as HTMLInputElement
        const target = event.currentTarget as HTMLInputElement
        const u = new URL(target.dataset.link!)
        u.searchParams.set("selected_dates", target.dataset.date ? target.dataset.date : selectedDates.value)
        Turbo.visit(u)
    }
}
