import { Controller } from "@hotwired/stimulus"
import * as am5 from "@amcharts/amcharts5"
import * as am5xy from "@amcharts/amcharts5/xy"
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated"

export default class extends Controller {
  connect() {
    // Create root element
    const root = am5.Root.new(this.element as HTMLElement)
    root._logo?.dispose()

    // Set themes
    root.setThemes([
      am5themes_Animated.new(root)
    ])


    // Create chart
    // https://www.amcharts.com/docs/v5/charts/xy-chart/
    const chart = root.container.children.push(am5xy.XYChart.new(root, {
      panX: false,
      panY: false,
      wheelX: "none",
      wheelY: "none",
      paddingLeft: 0,
      layout: root.verticalLayout
    }))

    // Create axes and their renderers
    const yRenderer = am5xy.AxisRendererY.new(root, {
      visible: false,
      minGridDistance: 10,
      inversed: true,
      minorGridEnabled: true
    })
    yRenderer.grid.template.set("visible", false)
    yRenderer.labels.template.setAll({
      fill: am5.color(0xffffff),
      fontSize: "12px",
      fontFamily: "Inter",
    })

    const yAxis = chart.yAxes.push(am5xy.CategoryAxis.new(root, {
      maxDeviation: 0,
      renderer: yRenderer,
      categoryField: "date"
    }))

    const xRenderer = am5xy.AxisRendererX.new(root, {
      visible: false,
      minGridDistance: 10,
      opposite:true,
      minorGridEnabled: true
    })
    xRenderer.grid.template.set("visible", false)
    xRenderer.labels.template.setAll({
      fill: am5.color(0xffffff),
      fontSize: "11px",
      fontFamily: "Inter",
    })

    const xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
      renderer: xRenderer,
      categoryField: "hour"
    }))

    // Create series
    const series = chart.series.push(am5xy.ColumnSeries.new(root, {
      calculateAggregates: true,
      stroke: am5.color(0x000000),
      clustered: false,
      xAxis: xAxis,
      yAxis: yAxis,
      categoryXField: "hour",
      categoryYField: "date",
      valueField: "state"
    }))

    const tooltip = am5.Tooltip.new(root, {
      autoTextColor: false,
      labelText: "[bold]{services}[/]"
    })
    
    tooltip.label.setAll({
      fill: am5.color(0xffffff)
    })
    

    series.columns.template.setAll({
      tooltipText: "{services}",
      tooltip: tooltip,
      strokeOpacity: 1,
      strokeWidth: 2,
      width: am5.percent(100),
      height: am5.percent(100)
    })

    series.columns.template.adapters.add("fill", function(fill, target) {
      if (target.dataItem) {
        // @ts-ignore
        const state = target.dataItem.get("value")
        switch (state) {
          case 0: return am5.color("#7ac13f")
          case 1: return am5.color("#ffb700")
          case 2: return am5.color("#fe4e59")
          default: return am5.color("#666")
        }
      }
      return fill
    });

    // Set data
    const data = JSON.parse((this.element as HTMLElement).dataset.data || "[]")
    series.data.setAll(data)

    //  Get dates for y-Axis
    const dates = data.map((x: any) => { return x["date"] })
    const unique = [...new Set(dates)]
    yAxis.data.setAll(unique.reverse().map((x) => {
      return { date: x }
    }))

    //  Set hours for x-Axis
    xAxis.data.setAll([
      { hour: 0 },
      { hour: 1 },
      { hour: 2 },
      { hour: 3 },
      { hour: 4 },
      { hour: 5 },
      { hour: 6 },
      { hour: 7 },
      { hour: 8 },
      { hour: 9 },
      { hour: 10 },
      { hour: 11 },
      { hour: 12 },
      { hour: 13 },
      { hour: 14 },
      { hour: 15 },
      { hour: 16 },
      { hour: 17 },
      { hour: 18 },
      { hour: 19 },
      { hour: 20 },
      { hour: 21 },
      { hour: 22 },
      { hour: 23 }
    ])

    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/#Initial_animation
    chart.appear(1000, 100)
  }
}
