import { Controller } from "@hotwired/stimulus"
import { fetchAndReplaceHistory, toggleItemInFilterSet } from "../../../engines/nc/app/javascript/nc/utils"

export default class extends Controller {
  static targets = ["eula", "submit", "filterItem", "customFilterModal"]
  readonly eulaTarget!: HTMLInputElement
  readonly submitTarget!: HTMLInputElement
  readonly customFilterModalTarget!: HTMLElement
  readonly filterItemTargets!: HTMLElement[]
  readonly filterValue!: string

  connect() {
    //  Set checkboxes according to current filter
    const u = new URL(window.location.href)
    const currentFilter = u.searchParams.get(this.filterValue) || ""
    const currentSet: string[] = currentFilter === "" ? [] : currentFilter.split(",")
    const checkboxes = this.element.querySelectorAll("[type='checkbox']")
    checkboxes.forEach((e) => {
      const c = e as HTMLInputElement
      const li = c.closest("li")
      const itemFilter = li?.dataset?.filter || "unset"
      const hasItem = currentSet.includes(itemFilter)
      c.checked = hasItem
      if (hasItem) {
        li?.setAttribute("aria-selected", "true")
      } else {
        li?.removeAttribute("aria-selected")
      }
    })
  }

  filterItemTargetConnected(element: HTMLElement) {
    const u = new URL(window.location.href)
    for (const activeFilter of u.searchParams.keys()) {
      const filterValues = u.searchParams.get(activeFilter)!.split(",")
      // Enable this checkbox if its filter value in in searchParams
      if (filterValues.includes(element.dataset.filter!)) this.toggleFilterCheckbox(element)
    }
  }

  customFilterModalTargetConnected() {
    for (const brandFilter of document.querySelectorAll(".filter-item[data-filter-type='brands']")) {
      this.toggleBrandCheckbox(brandFilter as HTMLElement)
    }
  }

  toggleFilterCheckbox(filterItem: HTMLElement, value: boolean | undefined = undefined) {
    const checkbox = filterItem.querySelector("input[type=checkbox]") as HTMLInputElement
    checkbox.checked = value == undefined ? !checkbox.checked : value
    return checkbox
  }

  filter(event: Event) {
    const target = event.target as HTMLElement
    const filterItem = target.closest(".filter-item")! as HTMLElement
    this.toggleFilterCheckbox(filterItem)
    const checkbox = filterItem.querySelector("input[type=checkbox]") as HTMLInputElement
    const filterName = filterItem.dataset.filter!
    const filterType = filterItem.dataset.filterType!

    const u = new URL(window.location.href)
    if (filterType == "brands") {
      for (const childFilter of this.getSubBrands(filterItem)) {
        toggleItemInFilterSet(u, "sub_brands", (childFilter as HTMLElement).dataset.filter!, checkbox.checked)
        this.toggleFilterCheckbox(childFilter as HTMLElement)
      }
    } else {
      toggleItemInFilterSet(u, filterType, filterName, checkbox.checked)
      let brandFilter = this.getBrand(filterItem)
      if (brandFilter) this.toggleBrandCheckbox(brandFilter)
    }
    fetchAndReplaceHistory(u.href)

    if (filterType == "brands") {
      const subBrandModal: NodeListOf<Element> = this.getSubBrandsModal(filterItem)
      if (subBrandModal && checkbox.checked) {
        let element: HTMLElement = subBrandModal[0] as HTMLElement
        element.click()
      }
    }
  }

  setFilterItem(filterItem: HTMLElement) {
    const checkbox = this.toggleFilterCheckbox(filterItem)
    const filterValue = filterItem.dataset.filter!
    const filterType = filterItem.dataset.filterType!

    const u = new URL(window.location.href)
    toggleItemInFilterSet(u, filterType, filterValue, checkbox.checked)
  }

  getBrand(subBrand: HTMLElement) {
    return document.querySelector(`.filter-item[data-filter='${subBrand.dataset.parentId!}']`) as HTMLElement
  }

  getSubBrands(brand: HTMLElement) {
    return document.querySelectorAll(`.filter-item[data-parent-id='${brand.dataset.filter}']`)
  }

  getSubBrandsModal(brand: HTMLElement) {
    return document.querySelectorAll(`.--modal-page-link[data-target='sub-brand_${brand.dataset.filter}']`)
  }

  toggleBrandCheckbox(filterItem: HTMLElement) {
    // If there are any child checkboxes AND there are none of these checkboxes that 
    // are unchecked, check this checkboxotherwise uncheck it
    let childCheckboxesSelector = `.filter-item[data-parent-id='${filterItem.dataset.filter}']`
    let childCheckboxes = document.querySelectorAll(childCheckboxesSelector)
    this.toggleFilterCheckbox(filterItem, (childCheckboxes.length > 0 && document.querySelectorAll(`${childCheckboxesSelector} input:not(:checked)`).length == 0))
  }
}