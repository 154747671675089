import { Controller } from "@hotwired/stimulus"
import { get, parseRequestOptionsFromJSON } from "@github/webauthn-json/browser-ponyfill"

export default class extends Controller {
  connect() {
    this.conditionalMediation()
  }

  async conditionalMediation() {
    if (window.PublicKeyCredential && PublicKeyCredential.isConditionalMediationAvailable) {
      //  Check if Conditional mediation is available
      const isCMA = await PublicKeyCredential.isConditionalMediationAvailable()
      if (!isCMA) {
        return
      }
  
      //  Get the path from a view element
      //  Since the form is behind a Flipper flag, this code essentially does nothing when the flag is disabled
      const formElement = this.element as HTMLFormElement
      if (!formElement) {
        return
      }
      const url = formElement.dataset.challengeUrl
      if (!url) {
        return
      }
  
      try {
        const authOptions = await fetch(url)
        const json = await authOptions.json()
  
        const options = parseRequestOptionsFromJSON({ ...json, mediation: "conditional" })
  
        //  Start AUTH ceremony, with conditional mediation
        const authResponse = await get(options)
  
        //  Set the hidden form field to contain the credential
        const fieldElement = formElement.querySelector("#authentication") as HTMLInputElement
        fieldElement.value = JSON.stringify(authResponse)
  
        //  Submit the form to validate the authentication
        formElement.submit()
      } catch (error) {
        // console.log(error)
        // FIXME - what to do here?
      }
    }
  }
}
