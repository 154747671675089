import { Application } from "@hotwired/stimulus"

declare global {
  interface Window {
    Stimulus: Application
  }
}

function buildApplication() {
  const application = Application.start()

  application.debug = false
  window.Stimulus = application

  return application
}

export default buildApplication
