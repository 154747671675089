import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "piiSelect", "roomPmsHint", "guestPmsHint", "pmsTypeSelect", "skytouchField", "nomadixField", "loyaltySelect", "loyaltyFields", "stayntouchField"]
  readonly piiSelectTarget!: HTMLInputElement
  readonly stayntouchFieldTargets!: HTMLInputElement[]
  readonly pmsTypeSelectTarget!: HTMLInputElement
  readonly loyaltySelectTarget!: HTMLInputElement
  readonly loyaltyFieldsTarget!: HTMLInputElement
  readonly roomPmsHintTarget!: HTMLElement
  readonly guestPmsHintTarget!: HTMLElement
  readonly nomadixFieldTargets!: HTMLInputElement[]
  readonly skytouchFieldTargets!: HTMLInputElement[]

  connect() {
    this.changeHint()
    this.changePmsType()
  }

  changeHint() {
    let value = this.piiSelectTarget.value
    if (value === "true") {
      this.roomPmsHintTarget.classList.add("hidden")
      this.guestPmsHintTarget.classList.remove("hidden")
    } else {
      this.roomPmsHintTarget.classList.remove("hidden")
      this.guestPmsHintTarget.classList.add("hidden")
    }
  }

  changePmsType() {
    this.toggleFields()
  }

  changeLoyaltyField() {
    let value = this.loyaltySelectTarget.value
    value == "loyalty_unset" ? this.loyaltyFieldsTarget.classList.add("hidden") : this.loyaltyFieldsTarget.classList.remove("hidden")
  }

  toggleFields() {
    const pmsType = this.pmsTypeSelectTarget.value
    const typeFieldMap = {
      "fias_nomadix": "nomadixField",
      "skytouch": "skytouchField",
      "stayntouch": "stayntouchField",
      "hotel_key": "hotelKeyField"
    }

    if (pmsType == "hotel_key") {
      document.querySelector("#property-id-label")!.classList.remove("hidden")
      document.querySelector("label[for='setting_hotel_code']")!.classList.add("hidden")
    } else {
      document.querySelector("#property-id-label")!.classList.add("hidden")
      document.querySelector("label[for='setting_hotel_code']")!.classList.remove("hidden")
    }

    const allFields = [this.nomadixFieldTargets, this.skytouchFieldTargets, this.stayntouchFieldTargets];
    allFields.forEach((fields) => {
      for (let targ of fields) {
        const input = targ as HTMLElement
        const label = input.parentElement?.querySelector(`label[for='${input.id}']`)
        if (input.dataset.pmsSettingsTarget!.includes(typeFieldMap[pmsType as keyof typeof typeFieldMap])) {
          input.classList.remove("hidden")
          if (label) label.classList.remove("hidden")
        } else {
          input.classList.add("hidden") 
          if (label) label.classList.add("hidden")
        }
      }
    })
  }
}
