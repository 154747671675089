// @ts-ignore
import { Turbo } from "@hotwired/turbo-rails"
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  visit(event: Event) {
    const currentTarget = event.currentTarget as HTMLInputElement
    const target = event.target as HTMLElement
    const link = target.closest('a') && target.closest('a') !== currentTarget.closest('a')
    if (link) {
      return;
    }
    Turbo.visit(currentTarget.dataset.link)
  }
}
