import { Controller } from "@hotwired/stimulus"

// Shows html with [data-select-target="dependent"] when select has a certain value.
//
// Usage:
// <div data-controller="select">
//   <select id="my-select" data-select-target="select" data-action="change->select#change">
//     <option value="1">One</option>
//     <option value="2">Two</option>
//   </select>
//
// <div data-select-target="dependent" data-select-id="my-select" data-select-value="1">
//   Visible when my-select value is 1
// </div>
//
export default class extends Controller {
  static targets = ["dependent", "select"]
  readonly selectTargets!: HTMLSelectElement[]
  readonly dependentTargets!: HTMLElement[]

  connect() {
    this.selectTargets.forEach((element) => this.change({ target: element }))
  }

  change({ target }: { target: HTMLSelectElement }) {
    this.dependentTargets
      .filter((element) => element.dataset.selectId === target.id)
      .forEach((element) => {
        element.dataset.selectValue === target.value
          ? element.classList.remove("hidden")
          : element.classList.add("hidden")
      })
  }
}
