import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["floorsCheckbox", "floorsFilter", "floorsSelected"]
  readonly floorsFilterTarget!: HTMLElement
  readonly floorsCheckboxTargets!: HTMLInputElement[]
  readonly floorsSelectedTargets!: HTMLInputElement[]
  declare readonly hasFloorsFilterTarget: boolean

  connect() {
    this.initialize_filter_ui()
  }

  initialize_filter_ui() {
    const currentUrl = new URL(window.location.href)
    const size = Array.from(currentUrl.searchParams).length
    if (size > 0) {
      for (const [key, value] of currentUrl.searchParams.entries()) {
        if (key == "floors") {
          this.initialize_floors_ui(value)
        } else {
          this.initialize_panel_ui(value)
        }
      }
    }
  }

  initialize_floors_ui(floorsString: string) {
    const buildingFloors = JSON.parse(floorsString)
    for (const [building, floors] of buildingFloors) {
      const buildingDiv = document.querySelector(`#${this.parse_building(building)}_floors`)
      for (const floor of floors) {
        const input = buildingDiv?.querySelector(`input[value='${floor}']`) as HTMLElement
        if (input) {
          this.set_floor_selected_ui(input.parentElement!)
        }
      }
    }
  }

  initialize_panel_ui(key: string) {
    const panels = document.querySelectorAll(".stat-panel") as NodeListOf<HTMLElement>
    for (const panel of panels) {
      let filterValue = panel.dataset.filterValue
      if ((filterValue == "occupied" && key == "occupied") || (filterValue == "vacant" && key == "vacant")) {
        this.set_panel_bg(panel)
      }
    }
  }

  minimise(event: Event) {
    const target = event.currentTarget as HTMLInputElement
    const containerName = target.dataset.tominimise || ""
    const toMinimise = document.querySelector("#" + containerName)
    toMinimise?.classList.toggle("hidden")
  }

  select_floor(event: Event) {
    const target = event.currentTarget as HTMLInputElement
    this.set_floor_selected_ui(target)
    const checkbox = target.children[0] as HTMLInputElement
    this.set_floors_for_building(target.dataset.building!, checkbox.value, checkbox.checked)
    this.filter()
  }

  set_floor_selected_ui(floorElement: HTMLElement) {
    floorElement.classList.toggle("selected")
    const checkbox = floorElement.children[0] as HTMLInputElement
    checkbox.checked = floorElement.classList.contains("selected")

  }

  set_floors_for_building(building: string, newFloor: string, add: boolean) {
    let buildingId = this.parse_building(building)
    let thisTag = document.querySelector('#' + buildingId + '_floors_selected') as HTMLInputElement
    let currentSet = thisTag.value.split(",")
    let newSet = currentSet.filter((value) => (value != '' && value != newFloor))
    if (add) newSet.push(newFloor)

    this.set_currently_selected_floors_for_building(newSet, building)
  }

  get_buildings() {
    let buildings = []

    for (let buildingFloorsSelected of this.floorsSelectedTargets) {
      buildings.push(buildingFloorsSelected.id.split("_floors_selected")[0])
    }

    return buildings
  }

  set_floors_filter_selected_bg() {
    let anySelected = false
    if (this.hasFloorsFilterTarget) {
      for (let checkbox of this.floorsCheckboxTargets) {
        if (checkbox.checked) {
          anySelected = true
          break
        }
      }
      const filterContent = this.floorsFilterTarget.closest(".stat-filter-content") as HTMLElement
      if (anySelected) {
        filterContent.setAttribute("aria-selected", "true")
      } else {
        filterContent.removeAttribute("aria-selected")
      }
    }
  }

  parse_building(building: String) {
    return building.split(' ').join('_')
  }

  set_currently_selected_floors_for_building(newSet: Array<String>, building: string) {
    const buildingId = this.parse_building(building)
    const thisTag = document.querySelector('#' + buildingId + '_floors_selected') as HTMLInputElement
    thisTag.value = newSet.join(",")
  }

  currently_selected_floors() {
    let building_floors = []

    for (let buildingFloorsSelected of this.floorsSelectedTargets) {
      let floors = buildingFloorsSelected.value.split(",")
      if (floors.length == 1 && floors[0] == '') floors = []
      let building = buildingFloorsSelected.id.split("_floors_selected")[0].split("_").join(" ")
      building_floors.push([building, floors])
    }

    return building_floors.filter((value) => value[1].length != 0)
  }

  get_rooms_url() {
    const frame = document.getElementById("rooms")
    if (!frame) {
      return null
    }
    const url = frame.getAttribute("src") || frame.dataset.url
    if (!url) {
      return null
    }

    return url
  }

  filter(event?: Event, url?: URL) {
    if (url == undefined) {
      let u = this.get_rooms_url()
      if (!u) {
        return
      }
      url = new URL(u)
    }

    document.querySelector("#loader")?.classList.toggle("!opacity-0")

    if (event) {
      const target = event.target as HTMLElement
      this.set_panel_bg(target.closest(".stat-panel") as HTMLElement)
    }

    this.set_floors_filter_selected_bg()

    url = this.set_search_params(url!)

    const frame = document.getElementById("rooms")
    frame!.setAttribute("src", url!.href)
  }

  set_panel_bg(statPanel?: HTMLElement) {
    if (statPanel) {
      if (statPanel.ariaSelected === "true") {
        statPanel.removeAttribute("aria-selected")
      } else {
        statPanel.setAttribute("aria-selected", "true")
      }
    }
  }

  set_page_params(params: URLSearchParams) {
    let pageUrl = new URL(window.location.href)
    for (const [key, value] of params.entries()) {
      pageUrl.searchParams.set(key, value)
    }

    for (const [key, value] of pageUrl.searchParams.entries()) {
      if (!params.get(key)) pageUrl.searchParams.delete(key)
    }
    window.history.pushState("rooms", "Rooms", pageUrl.toString())
  }

  set_search_params(u: URL) {
    const panels = document.querySelectorAll(".stat-panel[aria-selected='true']") as NodeListOf<HTMLElement>
    let enabledPanels = []
    for (const panel of panels) {
      enabledPanels.push(panel.dataset.filterValue)
    }

    if (enabledPanels.length > 0) {
      u.searchParams.set("status", enabledPanels.join(","))
    } else {
      u.searchParams.delete("status")
    }

    const selected_floors = this.currently_selected_floors()

    if (selected_floors.length == 0) {
      u.searchParams.delete("floors")
    } else {
      u.searchParams.set("floors", JSON.stringify(selected_floors))
    }

    u.searchParams.delete("page")
    this.set_page_params(u.searchParams)
    return u
  }

  clear_floor_filters(filter = true) {
    for (let checkbox of this.floorsCheckboxTargets) {
      checkbox.checked = false
      checkbox.parentElement?.classList.remove("selected")
    }

    this.set_building_checkboxes(false)

    for (let building of this.get_buildings()) {
      this.set_currently_selected_floors_for_building([], building)
    }

    if (filter) this.filter();
  }

  set_building_checkboxes(checked: boolean) {
    const buildingCheckboxes = document.querySelectorAll(".building-checkbox")
    for (let checkbox of buildingCheckboxes) {
      let box = checkbox as HTMLInputElement
      box.checked = checked
    }
  }

  select_building(event: Event) {
    const target = event.currentTarget as HTMLInputElement
    let building = this.parse_building(target.dataset.building!)
    let buildingFloors = document.querySelector("#" + building + '_floors')
    if (!buildingFloors) return
    let floorsToSelect = buildingFloors.querySelectorAll(".floor-select")
    for (let floor of floorsToSelect) {
      let checkbox = floor.children[0] as HTMLInputElement
      this.set_floors_for_building(target.dataset.building!, checkbox.value, target.checked)

      if (target.checked) {
        if (!floor.classList.contains("selected")) {
          floor.classList.add("selected")
          checkbox.checked = true
        }
      } else {
        if (floor.classList.contains("selected")) {
          floor.classList.remove("selected")
          checkbox.checked = false
        }
      }
    }

    this.filter()
  }

  all_floors(event: Event) {
    const target = event.currentTarget as HTMLInputElement
    const anySelected = document.querySelectorAll(".floor-select.selected").length > 0
    if (target.checked) {
      const values = []
      for (let checkbox of this.floorsCheckboxTargets) {
        checkbox.checked = true
        values.push(checkbox.value)
        if (!checkbox.parentElement?.classList.contains("selected")) checkbox.parentElement?.classList.add("selected")
      }

      this.set_building_checkboxes(true)

      for (let building of this.get_buildings()) {
        this.set_currently_selected_floors_for_building(values, building)
      }

      if (anySelected) this.filter()
    } else {
      const anyUnselected = document.querySelectorAll(".floor-select:not(.selected)").length > 0
      this.clear_floor_filters(anySelected && anyUnselected)
    }
  }

  clear_all_filters() {
    const url = this.get_rooms_url()
    if (!url) {
      return
    }
    const u = new URL(url)
    u.searchParams.delete("status")

    const panels = document.querySelectorAll(".stat-filter-content")
    for (let panel of panels) {
      panel.removeAttribute("aria-selected")
    }

    this.clear_floor_filters(false)
    this.filter(undefined, u)
  }
}
