import { Controller } from "@hotwired/stimulus"
import { i18n } from "../locales"

export default class extends Controller {
  static targets = ["locale"]
  readonly localeTarget!: HTMLInputElement

  change(event: Event) {
    const target = event.target as HTMLInputElement
    i18n.changeLanguage(target.value)
  }
}
