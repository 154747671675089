import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["email", "firstName", "lastName", "submit"]
  readonly emailTarget!: HTMLInputElement
  readonly firstNameTarget!: HTMLInputElement
  readonly lastNameTarget!: HTMLInputElement
  readonly submitTarget!: HTMLInputElement

  connect() {
    this.toggleSubmit()
  }

  toggleSubmit() {
    const noRole = !Array.from(
      this.element.querySelectorAll("input[type='radio']")
    ).some((c) => (c as HTMLInputElement).checked)

    this.submitTarget.disabled =
      noRole ||
      this.emailTarget.value === "" ||
      this.firstNameTarget.value === "" ||
      this.lastNameTarget.value === ""
  }
}
