import i18n from "i18next"

import en from "./en.json"

i18n.init({
  lng: "en",
  resources: {
    en: { translation: en }
  }
})

export { i18n }
