// @ts-ignore
import { Turbo } from "@hotwired/turbo-rails"
import { Controller } from "@hotwired/stimulus"
import { fetchAndReplaceHistory, toggleItemInFilterSet } from "../../../../../nc/app/javascript/nc/utils"

export default class extends Controller {
  static targets = ["keyword"]
  readonly keywordTarget!: HTMLFormElement

  static values = { filter: String }
  readonly filterValue!: string

  connect() {
    const u = new URL(window.location.href)
    if (u.searchParams.get(this.filterValue)) {
      this.keywordTarget.value = u.searchParams.get(this.filterValue)
    }
    this.keywordTarget.focus()
  }

  timer: number | null = null

  filter(e: Event) {
    if (this.timer) {
      clearTimeout(this.timer)
    }
    this.timer = setTimeout(() => {
      const input = e.target as HTMLInputElement
      const u = new URL(window.location.href)
      if (input.value) {
        u.searchParams.set(this.filterValue, input.value)
      } else {
        u.searchParams.delete(this.filterValue)
      }

      fetchAndReplaceHistory(u.href)
    }, 500)
  }

  clear() {
    //  Clear the field
    this.keywordTarget.value = ""

    //  Remove the filter
    const u = new URL(window.location.href)
    u.searchParams.delete(this.filterValue)
    toggleItemInFilterSet(u, "ignore", this.filterValue, false)

    fetchAndReplaceHistory(u.href)

    this.keywordTarget.focus()
  }
}
