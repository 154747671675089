import PopoverController from "./popover_controller"

export default class extends PopoverController {
  triggerClass = "popup__trigger"

  contentTarget() {
    return (this.hasPopoverContentTarget ? this.popoverContentTarget : document.getElementById("popup")) as HTMLElement
  }

  hasBackdrop() {
    return (this.trigger() as HTMLElement)?.dataset?.popupBackdrop !== undefined
  }

  templateId(e: Event) {
    return (e.currentTarget as HTMLElement).dataset?.popupTemplateId
  }
}
