import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["classReceiver"]
  readonly classReceiverTargets!: HTMLElement[]

  onScroll(event: Event) {
    let scrollContainer = event.target as HTMLElement
    const scrollTop = +(scrollContainer.dataset.scrollTop || "30")

    this.classReceiverTargets.forEach((target) => {
      let classes = target.dataset.scrollClasses || ""
      classes.split(" ").forEach((klass) => {
        if (scrollContainer.scrollTop > scrollTop) {
          target.classList.add(klass)
        } else {
          target.classList.remove(klass)
        }
      })
    })
  }
}