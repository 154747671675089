import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { timeout: Number }
  readonly timeoutValue!: number

  private abortController!: AbortController
  private timeoutId?: number

  actionCableConsumer?: any
  turbo?: any

  connect() {
    this.abortController = new AbortController()
    this.setupTimeout()
    this.setupEventListeners()
  }

  setupEventListeners() {
    const { signal } = this.abortController

    document.addEventListener("turbo:load", this.resetTimeout.bind(this), { signal })
  }

  setupTimeout() {
    if (!this.timeoutValue || this.timeoutValue <= 0) {
        return
    }

    this.timeoutId = setTimeout(this.stopConnections.bind(this), this.timeoutValue)
  }

  resetTimeout() {
    this.clearTimeout()
    this.setupTimeout()
  }

  clearTimeout() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId)
    }
  }

  stopConnections() {
    this.turbo?.session?.stop()
    this.actionCableConsumer?.subscriptions?.subscriptions?.forEach((subscription: any) => {
      subscription.unsubscribe()
    })
  }

  disconnect() {
    this.clearTimeout()
    this.abortController.abort()
  }
}
