import { Controller } from "@hotwired/stimulus"
import { fetchAndReplaceHistory } from "../../../../../nc/app/javascript/nc/utils"

export default class extends Controller {
  static targets = ["table", "cards"]
  readonly tableTarget!: HTMLElement
  readonly cardsTarget!: HTMLElement

  tableView() {
    if (this.tableTarget.classList.contains("active")) {
      return
    }
    this.setView("table")
    this.tableTarget.classList.remove("inactive")
    this.tableTarget.classList.add("active")
    this.cardsTarget.classList.remove("active")
    this.cardsTarget.classList.add("inactive")
  }

  cardsView() {
    if (this.cardsTarget.classList.contains("active")) {
      return
    }
    this.setView("cards")
    this.tableTarget.classList.remove("active")
    this.tableTarget.classList.add("inactive")
    this.cardsTarget.classList.remove("inactive")
    this.cardsTarget.classList.add("active")
  }

  setView(view: string) {
    const u = new URL(window.location.href)
    u.searchParams.delete("v")
    if (view === "cards") {
      u.searchParams.set("v", view)
    }

    fetchAndReplaceHistory(u.href)
  }
}
