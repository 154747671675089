import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["container", "sidebarContent", "backdrop"]
  declare readonly containerTarget: HTMLElement
  declare readonly sidebarContentTarget: HTMLElement
  declare readonly backdropTarget: HTMLElement

  connect() {
    window.addEventListener("keydown", this.handleKeydownEvent)
  }

  disconnect() {
    window.removeEventListener("keydown", this.handleKeydownEvent)
  }

  handleKeydownEvent = (event: KeyboardEvent) => {
    if (event.key === "Escape") { this.close() }
  }

  contentTarget() {
    return (this.sidebarContentTarget ? this.sidebarContentTarget : document.getElementById("sidebarContent")) as HTMLElement
  }

  //  Called when the sidebar is loaded (turbo-stream custom event sidebarOpen)
  open() {
    if (this.backdropTarget) { this.backdropTarget.classList.remove("hidden") }     
    this.containerTarget.classList.add("open")
  }

  close() {
    this.containerTarget.classList.remove("open")
    const content = this.contentTarget()
    if (!content){
      return
    }
    const customEvent = new CustomEvent("popoverClose"); 
    window.dispatchEvent(customEvent);
    content.style.opacity = '0';
    content.addEventListener("transitionend", () => {
      content.innerHTML = '';
      content.style.opacity = '1';
    }, { once: true })

    if (this.backdropTarget) { this.backdropTarget?.classList.add("hidden") }
  }
}
