// @ts-ignore
import { Turbo } from "@hotwired/turbo-rails"
import { Controller } from "@hotwired/stimulus"
import { create, parseCreationOptionsFromJSON } from "@github/webauthn-json/browser-ponyfill"

export default class extends Controller {
  static values = { challengeUrl: String, verifyUrl: String }
  readonly challengeUrlValue!: string
  readonly verifyUrlValue!: string

  async register() {
    try {
      //  GET registration challenge
      const resp = await fetch(this.challengeUrlValue)
      const json = await resp.json()
      const options = parseCreationOptionsFromJSON(json)

      //  Start the registration ceremony (should display OS modal to obtain credential)
      const credentialResponse = await create(options)

      //  Get the CSRF token
      const csrfElement = document.querySelector("[name='csrf-token']") as HTMLMetaElement
      const csrfToken = csrfElement.content
  
      //  POST the credential back to server for verification
      const verificationResponse = await fetch(this.verifyUrlValue, {
        method: 'POST',
        headers: {
          "X-CSRF-Token": csrfToken,
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ passkey: credentialResponse })
      })
  
      //  Wait for the results of verification
      const verificationJSON = await verificationResponse.json()
      if (verificationJSON.success) {
        //  Go to the page the controller says we should go to after success
        Turbo.visit(verificationJSON.url)
      }

      //  FIXME - error scenarios are not properly handled here
      //        - what happens if the fetches fail or the ceremony fails?

    } catch (error) {
      console.log("ERROR WAS CAUGHT:")
      console.log(error)
      return
    }
  }
}
