import { Controller } from "@hotwired/stimulus"
import { fetchAndReplaceHistory } from "../../../../../nc/app/javascript/nc/utils"

export default class extends Controller {
    static targets = ["refreshElement"]
    readonly refreshElementTarget!: HTMLElement

    TIMEOUT_MSEC_DEFAULT = 600000
    timeoutId = 0

    refreshElementTargetConnected() {
        clearTimeout(this.timeoutId)

        const timeout = +(this.refreshElementTarget.dataset.timeout || this.TIMEOUT_MSEC_DEFAULT)

        this.timeoutId = setTimeout(() => {
            fetchAndReplaceHistory(window.location.href)
        }, timeout);
    }
}
