// @ts-ignore
import { Turbo } from "@hotwired/turbo-rails"
import { Controller } from "@hotwired/stimulus"
import { fetchAndReplaceHistory, fetchTurboStream, toggleItemInFilterSet } from "../utils"

export default class extends Controller {
  static values = { filter: String, secondaryFilter: String, url: String }
  readonly filterValue!: string
  readonly secondaryFilterValue!: string
  readonly urlValue!: string

  static targets = ["panel"]
  readonly panelTarget!: HTMLElement

  toggle() {
    if (this.isOpen()) {
      return
    }

    const currentlyOn = this.panelTarget.getAttribute("aria-selected") === "true"
    const u = new URL(window.location.href)
    toggleItemInFilterSet(u, "ignore", this.filterValue, currentlyOn)

    if (currentlyOn) {
      this.panelTarget.removeAttribute("aria-selected")
    } else {
      this.panelTarget.setAttribute("aria-selected", "true")
    }

    fetchAndReplaceHistory(u.href)
  }

  edit(e: Event) {
    //  We stop propagation so the event doesn't also trigger a pill toggle
    e.stopPropagation()

    if (this.isOpen()) {
      return
    }

    fetchTurboStream(this.urlValue)
  }

  close() {
    if (this.isOpen()) {
      return
    }

    const u = new URL(window.location.href)
    u.searchParams.delete(this.filterValue)
    toggleItemInFilterSet(u, "ignore", this.filterValue, false)
    //  Remove any child filters which do not have a pill
    if(this.secondaryFilterValue){
      u.searchParams.delete(this.secondaryFilterValue)
      toggleItemInFilterSet(u, "ignore", this.secondaryFilterValue, false)
    }

    fetchAndReplaceHistory(u.href)
  }

  isOpen() {
    const selector = (this.element as HTMLElement).dataset?.isOpenSelector ?? "div[data-controller='custom-filters']"
    return document.querySelector(selector)
  }
}
