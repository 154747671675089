import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["eula", "submit"]
  readonly eulaTarget!: HTMLInputElement
  readonly submitTarget!: HTMLInputElement

  connect() {
    this.toggleSubmit()
  }

  toggleSubmit() {
    this.submitTarget.disabled = !this.eulaTarget.checked
  }
}
