import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    setTimeout(
      () =>
        this.element.classList.add(
          "transition-opacity",
          "ease-out",
          "duration-500",
          "opacity-0"
        ),
      8000
    )
  }

  hide() {
    this.element.classList.add("hidden")
  }
}
