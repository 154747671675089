import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { preferredScheme: String }
  readonly preferredSchemeValue!: string
  readonly darkModeMediaQuery = window.matchMedia(
    "(prefers-color-scheme: dark)"
  )

  initialize() {
    this.darkModeMediaQuery.addEventListener("change", (e) => {
      if (this.preferredSchemeValue === "auto") {
        this.setMode(e.matches ? "dark" : "light")
      }
    })
  }

  connect() {
    if (this.preferredSchemeValue === "auto") {
      this.darkModeMediaQuery.matches
        ? this.setMode("dark")
        : this.setMode("light")
    } else {
      this.setMode(this.preferredSchemeValue)
    }
  }

  setMode(mode: string) {
    const content = document.querySelector("html")
    content?.classList.remove("dark", "light")
    content?.classList.add(mode)
  }
}
