import { Controller } from "@hotwired/stimulus"
import { i18n } from "../locales"

export default class extends Controller {
  static targets = ["button", "timeZone", "twoFactor", "password", "hint"]
  readonly buttonTarget!: HTMLInputElement
  readonly timeZoneTarget!: HTMLInputElement
  readonly twoFactorTarget!: HTMLInputElement
  readonly passwordTargets!: HTMLInputElement[]
  readonly hintTargets!: HTMLInputElement[]
  passwordHint!: HTMLElement | undefined
  confirmationHint!: HTMLElement | undefined

  connect() {
    this.timeZoneTarget.value =
      Intl.DateTimeFormat().resolvedOptions().timeZone ?? "Etc/UTC"
    this.passwordHint = this.hintTargets.find(hint => hint.id === "password")
    this.confirmationHint = this.hintTargets.find(hint => hint.id === "password_confirmation")
    this.passwordTargets[0].setCustomValidity(i18n.t("password.custom_validation"))
    this.passwordTargets[1].setCustomValidity(i18n.t("password.confirmation_custom_validation"))
  }

  toggle(event: Event) {
    const target = event.target as HTMLInputElement
    this.buttonTarget.value = target.checked
      ? i18n.t("two_factor.enable")
      : i18n.t("create_account")
    this.twoFactorTarget.value = target.checked.toString()
  }

  showPassword(event: Event) {
    const target = event.target as HTMLInputElement
    const password = target.parentElement!.previousElementSibling! as HTMLInputElement
    const passwordInput = password.querySelector('[id^="user_password"]') as HTMLInputElement
    if (passwordInput.type === "password") {
      target.textContent = "visibility"
      passwordInput.type = "text"
    }  else {
      target.textContent = "visibility_off"
      passwordInput.type = "password"
    }
  }

  verifyPasswordField() {
    const password = this.passwordTargets[0] as HTMLInputElement
    const confirmation = this.passwordTargets[1] as HTMLInputElement
    const passwordCheck = password.parentElement!.nextElementSibling!.querySelector(".check")as HTMLElement
    const confirmationCheck = confirmation.parentElement!.nextElementSibling!.querySelector(".check") as HTMLElement
    let hideCheck = true
    let hideConfirmationCheck = true

    password.setCustomValidity(i18n.t("password.custom_validation"))
    confirmation.setCustomValidity(i18n.t("password.confirmation_custom_validation"))
    if (password.value && !password.validity.tooShort) {
      hideCheck = false
      password.setCustomValidity(i18n.t(""))
      if (this.passwordTargets[0].value === this.passwordTargets[1].value) {
        hideConfirmationCheck = false
        confirmation.setCustomValidity(i18n.t(""))
      }
    }
    this.toggleVisibility(passwordCheck, hideCheck)
    this.toggleVisibility(confirmationCheck, hideConfirmationCheck)
  }

  toggleVisibility(element: HTMLElement | undefined, visible: boolean) {
    if (element) {
        if (visible) {
            element.classList.add("hidden")
        } else {
            element.classList.remove("hidden")
        }
    }
  }

  checkSubmit(event: Event) {
    const password = this.passwordTargets[0] as HTMLInputElement
    const confirmation = this.passwordTargets[1] as HTMLInputElement
    if(!confirmation.checkValidity()) {
      this.passwordHint!.textContent = password.validationMessage
      this.confirmationHint!.textContent = confirmation.validationMessage
      event.preventDefault()
    } 
  }
}