import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "step3",
    "step3Icon",
    "step3Number",
    "step3Title",
    "step4",
    "step4Title"
  ]
  readonly step3Target!: HTMLElement
  readonly step3TitleTarget!: HTMLElement
  readonly step3IconTarget!: HTMLElement
  readonly step3NumberTarget!: HTMLElement
  readonly step4Target!: HTMLElement
  readonly step4TitleTarget!: HTMLElement

  step3Toggle() {
    this.step3Target.classList.add("hidden")
    this.step3TitleTarget.classList.add("opacity-25")
    this.step3IconTarget.textContent = "check_circle"
    this.step3NumberTarget.style.display = "none"
    this.step4Target.classList.remove("hidden")
    this.step4TitleTarget.classList.remove("opacity-25")
  }
}
