// @ts-ignore
import { Turbo } from "@hotwired/turbo-rails"

export function fetchTurboStream(url: string) {
  fetch(url, { headers: { Accept: "text/vnd.turbo-stream.html" } })
    .then((response) => response.text())
    .then((html) => Turbo.renderStreamMessage(html))
}

export function fetchAndReplaceHistory(url: string) {
  fetchTurboStream(url)
  history.replaceState(null, "", url)
}

export function toggleItemInFilterSet(u: URL, filterName: string, item: string, isEnabled: boolean) {
  const currentParam = u.searchParams.get(filterName) || ""
  const currentSet = currentParam == "" ? [] : currentParam.split(",")

  const newSet = currentSet.filter((value) => value != item)
  if (isEnabled) {
    newSet.push(item)
  }

  const newParam = newSet.join(",")
  if (newParam != "") {
    u.searchParams.set(filterName, newParam)
  } else {
    u.searchParams.delete(filterName)
  }
}
