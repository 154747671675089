// @ts-ignore
import { Turbo } from "@hotwired/turbo-rails"
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["queuedRequest"]
  readonly queuedRequestTargets!: HTMLElement[]

  queuedRequestTargetConnected(element: HTMLElement) {
    const parent = element.parentElement as HTMLElement
    const u = parent.dataset.fetchUrl!
    const url = new URL(u)
    url.searchParams.set("item_id", element.dataset.value!)
    for (let key in parent.dataset) {
      if (key == "fetchUrl" || key == "controller") continue;
      url.searchParams.set(this.camelToUnderscore(key), parent.dataset[key]!)
    }
    fetch(url, {
      headers: {
        Accept: "text/vnd.turbo-stream.html",
      }
    })
      .then((response) => { response.text().then((html) => { Turbo.renderStreamMessage(html); element.remove() }) })
  }

  camelToUnderscore(key: string) {
    var result = key.replace(/([A-Z])/g, " $1");
    return result.split(' ').join('_').toLowerCase();
  }
}
