import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["pills"]
  readonly pillsTarget!: HTMLElement

  observer: MutationObserver | null = null

  connect() {
    this.observer = new MutationObserver(() => {
      //  Check if there are pills or no pills
      const hasPills = this.pillsTarget.children.length > 0
      if (hasPills) {
        this.element.classList.add("with-pills")
      } else {
        this.element.classList.remove("with-pills")
      }
    })
    if (this.pillsTarget.parentElement) {
      this.observer.observe(this.pillsTarget.parentElement, { childList: true, subtree: true })
    }
  }

  disconnect(): void {
    this.observer?.disconnect()
  }
}
