import { Controller } from "@hotwired/stimulus"
import { fetchAndReplaceHistory, toggleItemInFilterSet } from "../utils"

export default class extends Controller {
  static values = { returnToPrimaryFilter : Boolean }
  static targets = ["menu", "filter", "secondaryFilter", "slider", "wrapper"]
  readonly menuTarget!: HTMLElement
  readonly filterTargets!: HTMLElement[]
  readonly secondaryFilterTargets!: HTMLElement[]
  readonly sliderTarget!: HTMLElement
  readonly wrapperTarget!: HTMLFormElement
  sliderWidth: number = this.menuTarget.clientWidth + 10
  currentSliderPosition: number = 0
  readonly returnToPrimaryFilterValue!: boolean

  connect() {
    //  Disable filter menu items if we already have a filter pill
    const u = new URL(window.location.href)
    const menuItems = this.menuTarget.querySelectorAll("li")
    menuItems.forEach((e) => {
      const item = e as HTMLElement
      const filter = item.dataset.filter
      if (filter && u.searchParams.get(filter)) {
        item.classList.add("disabled")
      }
    })
  }

  filterSecondary(event: CustomEvent) {
    const target = event.target as HTMLElement
    const toggled = event.detail.toggled
    const u = event.detail.u
    const filterItem = target.dataset.filter!
    const filterType = target.dataset.filterType!
    const secondaryFilterType = target.dataset.secondaryFilterType!
    const secondaryFilterItems = this.getSecondaryFilterItems(filterItem)
  
    if(secondaryFilterItems.length > 0) {
      if (toggled) {
        const selectedTarget = this.secondaryFilterTargets.find((t) => {
          return t.dataset.filter === secondaryFilterType
        }) as HTMLElement

        const primaryFilterTarget = this.filterTargets.find((t) => {
          return t.dataset.filter === filterType
        }) as HTMLElement

          // Get the parent node of both divs
          const parentNode = selectedTarget.parentNode
          if (parentNode) {
            parentNode.insertBefore(selectedTarget, primaryFilterTarget)
          }

        //  Slide the slider an additional time and show secondary filter
        this.sliderTarget.style.left = (this.currentSliderPosition -= this.sliderWidth) + "px"
        
        selectedTarget.classList.remove("hidden")
        selectedTarget.classList.add("relative")
        selectedTarget.style.left = this.sliderWidth + "px"
        this.showSecondaryFilterItems(filterItem, toggled, u, secondaryFilterType)    

      } else {
        this.deselectFilterItems(u, secondaryFilterItems, secondaryFilterType)
      }
    }
    fetchAndReplaceHistory(u.href)
  }

  selectFilter(e: Event) {
    const el = e.currentTarget as HTMLElement
    if (el.classList.contains("disabled")) {
      return
    }

    const selectedFilter = el.dataset.filter
    const selectedTarget = this.filterTargets.find((t) => {
      return t.dataset.filter === selectedFilter
    })

    if (selectedTarget) {
      //  Reveal the selected filter
      selectedTarget.classList.remove("hidden")

      //  Slide the slider and animate height
      this.sliderTarget.style.left = (this.currentSliderPosition -= this.sliderWidth) + "px"
      this.wrapperTarget.style.height = selectedTarget.clientHeight + "px"
    }
  }

  back() {
    //  Slide the slider and animate height
    this.sliderTarget.style.left = (this.currentSliderPosition += this.sliderWidth) + "px"
    
    if (this.currentSliderPosition === 0 && !this.returnToPrimaryFilterValue){
      this.wrapperTarget.style.height = this.menuTarget.clientHeight + "px"
      this.filterTargets.forEach((t) => {
        t.classList.add("hidden")
      })
    } else {
      //  Hide secondary menus
      this.secondaryFilterTargets.forEach((t) => {
        t.classList.add("hidden")
      })    
    }
  }

  showSecondaryFilterItems(primaryFilterValue: String, toggled: boolean, u: URL, secondaryFilterType: string) {
    const secondaryFilterItems = this.getSecondaryFilterItems(primaryFilterValue)
    this.showFilterItems(secondaryFilterItems, toggled)
    
    if (toggled) {
      this.showFilterItems(this.getNonSecondaryFilterItemsForFilter(primaryFilterValue), false)
    } 
    else {
      this.deselectFilterItems(u, secondaryFilterItems, secondaryFilterType)
    }
  }

  getSecondaryFilterItems(primaryFilterValue: String) {
    return document.querySelectorAll(`[data-parent-id='${primaryFilterValue}']`)
  }

  getNonSecondaryFilterItemsForFilter(primaryFilterValue: String) {
    return document.querySelectorAll(`[data-parent-id]:not([data-parent-id='${primaryFilterValue}'])`)
  }

  showFilterItems(filters: NodeListOf<Element>, show: boolean) {
    const method = show ? 'remove' : 'add'
    for (const filter of filters) {
        filter.classList[method]("hidden")
    }
  }

  deselectFilterItems(u: URL, filters: NodeListOf<Element>, filter: string) {
    const values = u.searchParams.get(filter)

    if (values) {
      let newValues = values.split(',')
  
      for (const filter of filters) {
        newValues = newValues.filter(value => value !== filter.getAttribute("data-filter"))
      }
  
      u.searchParams.set(filter, newValues.join(','))
  
      if(newValues.length === 0){
        u.searchParams.delete(filter)
      }
    }
  }
}

