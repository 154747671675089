import { Controller } from "@hotwired/stimulus"
import * as am5 from "@amcharts/amcharts5"
import * as am5xy from "@amcharts/amcharts5/xy"
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { railsToJsTimezone } from "../rails_to_js_timezone"

export default class extends Controller {
  static values = { type: { type: String, default: "stacked_column" } }
  root!: am5.Root
  pieChart!: am5percent.PieChart
  xyChart!: am5xy.XYChart
  xAxis!: am5xy.DateAxis<am5xy.AxisRenderer>
  yAxis!: am5xy.ValueAxis<am5xy.AxisRenderer>
  color!: am5.Color
  chartOptions!: { [key: string]: any }

  readonly typeValue!: string

  readonly chartTypes = {
      STACKED_COLUMN: "stacked_column",
      LINE: "line",
      RING: "ring",
      STEP_LINE: "step_line"
  }

  readonly oneDayInMs= 24 * 60 * 60 * 1000

  connect() {
    //  Determine whether dark mode is active
    const content = document.querySelector("html") as HTMLElement
    const dark = content?.classList.contains("dark")

    const existingController = content.dataset.controller || ""
    if (!existingController.includes("class-observer"))
      content.dataset.controller = `${existingController} class-observer`
    content.dataset.classObserverTarget = "observee"
    const existingTarget = content.dataset.eventTarget || ""
    const element = this.element as HTMLElement
    if (!existingTarget.includes(element.id))
      content.dataset.eventTarget = `${existingTarget} #${element.id}`

    //  Custom options
    this.chartOptions = JSON.parse(element.dataset.options || "{}")

    //  Set colours that vary between light/dark mode
    this.color = dark ? am5.color(0xffffff) : am5.color(0x000000)

    //  Create root element + chart
    this.root = am5.Root.new(element.id, {
        tooltipContainerBounds: {
        top: 50,
        right: 100,
        bottom: 50,
        left: 100
      }
    })
    this.root._logo?.dispose()
    // Set themes
    this.root.setThemes([
      am5themes_Animated.new(this.root)
    ])

    if (this.typeValue === this.chartTypes.RING) {
      // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/
      this.pieChart = this.root.container.children.push(am5percent.PieChart.new(this.root, {
        layout: this.root.verticalLayout,
        innerRadius: am5.percent(70)
      }))

      // Make stuff animate on load
      // https://www.amcharts.com/docs/v5/concepts/animations/#Initial_animation
      this.pieChart.appear(300, 100)
    } else {
      this.xyChart = this.root.container.children.push(am5xy.XYChart.new(this.root, {    
        layout: this.root.verticalLayout
       }))

       //  y-Axis
      this.yAxis = this.xyChart.yAxes.push(
        am5xy.ValueAxis.new(this.root, {
        min: 0,
        renderer: am5xy.AxisRendererY.new(this.root, {})
        })
      )

      //  x-Axis configured with varying baseInterval depending on selected period
      const timeSpec = JSON.parse(this.chartOptions.timespec || "{}")
      this.xAxis = this.xyChart.xAxes.push(
        am5xy.DateAxis.new(this.root, {
          renderer: am5xy.AxisRendererX.new(this.root, { minGridDistance: 50 }),
          baseInterval: timeSpec,
          tooltip: am5.Tooltip.new(this.root, {
              getFillFromSprite: false
          })
        })
      )
    }
    this.createChart()
  }

  classlistChanged() {
    const dark = document.querySelector("html")!.classList.contains("dark")
    this.color = dark ? am5.color(0xffffff) : am5.color(0x000000)
    this.createChart()
  }

  createChart() {
    //  Inject the chart data
    const data = JSON.parse((this.element as HTMLElement).dataset.dataset || "[]")
    switch (this.typeValue) {
      case this.chartTypes.STACKED_COLUMN:
        this.configureXYStyling({ strokeOpacity: 0 })
        this.columnSeries(data)
        break;
      case this.chartTypes.LINE:
        this.configureXYStyling()
        this.lineSeries(data)
        break;
      case this.chartTypes.STEP_LINE:
        this.configureXYStyling()
        this.stepLineSeries(data)
        break;
      case this.chartTypes.RING:
        this.pieSeries(data)
        break;
      default:
        throw new Error(`Invalid chart type: ${this.typeValue}`);
      }
  }

  configureXYStyling(xRendererGridOptions:{}={stroke: this.color, strokeOpacity: 0.1, strokeWidth: 1}){
    //  Create chart cursor
    this.xyChart.set("cursor", am5xy.XYCursor.new(this.root, {}))

    //  Configure cursor styling
    const cursor = this.xyChart.get("cursor")
    cursor?.lineX.setAll({
      stroke: this.color,
      strokeOpacity: 0.4,
      strokeWidth: 1,
      strokeDasharray: [2, 2]
    });

    cursor?.lineY.setAll({
      stroke: this.color,
      strokeOpacity: 0.4,
      strokeWidth: 1,
      strokeDasharray: [2, 2],
      visible: false
    });
    const xy = this.chartOptions.styling?.xy || {}
    const hideTooltipX = xy.additionalProps?.hideTooltipX
    if (hideTooltipX) {
      this.xAxis.set("tooltip", undefined);
    } else {
      const tooltipX = this.xAxis.get("tooltip") as am5.Tooltip
      tooltipX?.get("background")?.setAll({
        fill: this.color,
        fillOpacity: 1,
        strokeOpacity: 0
      }) 
      this.setTooltipPosition(tooltipX)
    }

    const xRenderer = this.xAxis.get("renderer")
    xRenderer.grid.template.setAll(xRendererGridOptions)
    xRenderer.labels.template.setAll({
      fill: this.color,
      fontSize: "14px",
      fontFamily: "Inter",
      fontWeight: "500",
      location: 0,
      ...xy.x
    })

    //  Configure y-Axis styling
    const yRenderer = this.yAxis.get("renderer")
    yRenderer.grid.template.setAll({
      stroke: this.color,
      strokeWidth: 1,
      strokeOpacity: 0.1
    })
    yRenderer.labels.template.setAll({
      fill: this.color,
      fontSize: "12px",
      fontFamily: "Inter",
      textAlign: "center",
      ...xy.y
    })
  }

  columnSeries(data: any) {
    this.root.numberFormatter.set("numberFormat", "#")
    this.xAxis.get("dateFormats")!["hour"] = "ha"
    const timezone = this.chartOptions.timezone ?? null
    if (timezone) this.root.timezone = am5.Timezone.new(railsToJsTimezone[timezone])
    if (this.chartOptions.fixedOneDayChart) {
      this.xAxis.get("dateFormats")!["hour"] = "HH"
      this.xAxis.get("tooltipDateFormats")!["hour"] = "HH:mm"
      this.xAxis.set("markUnitChange", false)
  
      const currentDate = new Date()
      const startTime = currentDate.getTime() - this.oneDayInMs;
      if (currentDate.getMinutes() > 0 || currentDate.getSeconds() > 0 || currentDate.getMilliseconds() > 0) {
        currentDate.setHours(currentDate.getHours() + 1);
      }
  
      this.xAxis.set("min", startTime);
      this.xAxis.set("max", currentDate.getTime());
      this.xAxis.set("gridIntervals", [
        { timeUnit: "hour", count: 2 }
      ])
    }

    const seriesSettings = JSON.parse(
        this.chartOptions.seriesSpec || "[]"
      )
  
    this.xyChart.series.clear() // Required when changing light/dark otherwise re-renders on top
    seriesSettings.forEach(({ key, color }: { key: string; color: string }) => {
      // Add series
      // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
      const series = this.xyChart.series.push(
        am5xy.ColumnSeries.new(this.root, {
        stacked: true,
        xAxis: this.xAxis,
        yAxis: this.yAxis,
        valueYField: key,
        valueXField: "x",
        tooltip: this.configureSeriesTooltip(),
        stroke: am5.color(color),
        fill: am5.color(color)
        })
      )

      series.columns.template.setAll({
        width: am5.percent(67),
        cornerRadiusTL: 2, // TODO: only for the top of the stack
        cornerRadiusTR: 2
      })

      series.data.setAll(data)
    })
  }

  lineSeries(data: any) {
    this.root.numberFormatter.set("numberFormat", "#.000");
    const tooltip = this.configureSeriesTooltip()
    this.setTooltipPosition(tooltip, -15)

    this.xyChart.series.clear() // Required when changing light/dark otherwise re-renders on top
    const series = this.xyChart.series.push(am5xy.SmoothedXYLineSeries.new(this.root, {
        minBulletDistance: 10,
        connect: false,
        tension: 0.75,
        xAxis: this.xAxis,
        yAxis: this.yAxis,
        valueYField: "y",
        valueXField: "x",
        tooltip: tooltip,
        stroke: am5.color("#b410b8"),
        fill: am5.color("#b410b8")
      }))

    const gradient = am5.LinearGradient.new(this.root, {
      stops: [{
        color: am5.color("#cc51d7"),
        opacity: 0.2
      }, {
        color: am5.color("#204b73"),
        opacity: 0
      }]
    })

    series.strokes.template.setAll({
      strokeWidth: 2
    })
    series.fills.template.setAll({    
      fillGradient: gradient,
      visible: true
    })
    series.data.setAll(data)
  }

  stepLineSeries(data: any) {
    const tooltip = this.configureSeriesTooltip()
    const chartStyling = this.chartOptions.styling
    const lineColor = chartStyling?.lineColor ? am5.color(chartStyling.lineColor) : this.color
    this.xyChart.series.clear() // Required when changing light/dark otherwise re-renders on top
    const series = this.xyChart.series.push(am5xy.StepLineSeries.new(this.root, {
        xAxis: this.xAxis,
        yAxis: this.yAxis,
        valueYField: "y",
        valueXField: "x",
        tooltip: tooltip,
        stroke: lineColor,
        }));

    series.strokes.template.setAll({
      strokeWidth: 2
    })
    series.data.setAll(data)
  }

  pieSeries(data: any){
    // Create series
    // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/#Series
    this.pieChart.series.clear() // Required when changing light/dark otherwise re-renders on top
    const series = this.pieChart.series.push(am5percent.PieSeries.new(this.root, {
      valueField: "value",
      categoryField: "category",
      alignLabels: false,
      tooltip: am5.Tooltip.new(this.root, {
        pointerOrientation: "horizontal",
        labelText: "[fontSize:12px;]{category}[/]"
      })
    }));

    series.set("colors", am5.ColorSet.new(this.root, { 
      colors: [
      am5.color(0x6A7280),
      am5.color(0x90C058),
      am5.color(0xFFC600),
      am5.color(0xFE4E59),
      ]
    }));

    series.ticks.template.set("forceHidden", true);
    series.labels.template.set("forceHidden", true);

    const dark = document.querySelector("html")!.classList.contains("dark")
    series.slices.template.setAll({
      cornerRadius: 8,
      strokeWidth: 2,
      stroke: am5.Color.fromString(dark ? "#000" : "#f0f3f7")
    })

    const formattedData = [
      { category: "No data", value: data.filter((bucket: any) => (bucket["state"] == null)).length },
      { category: "Good", value: data.filter((bucket: any) => (bucket["state"] == "0")).length },
      { category: "Warning", value: data.filter((bucket: any) => (bucket["state"] == "1")).length },
      { category: "Alert", value: data.filter((bucket: any) => (bucket["state"] == "2")).length },
    ]
    series.data.setAll(formattedData)
  }

  setTooltipPosition(tooltip: am5.Tooltip, yPosition: number = 0) {
    tooltip.adapters.add("bounds", (bounds, target) => {
      const pointTo = target.get("pointTo")
      if (bounds && pointTo) {
        bounds.left = pointTo.x - (target.width() / 2)
        bounds.right = pointTo.x + (target.width() / 2)
      }
      return bounds
    })

    if (yPosition != 0) {
      tooltip.adapters.add("y", () => {
        return yPosition
      })
    }
  }

  configureSeriesTooltip(){
    const tooltipOptions = this.chartOptions.styling?.tooltip || {}
    const tooltip = am5.Tooltip.new(this.root, {
      pointerOrientation: "horizontal",
      labelText: "{valueY}",
      ...tooltipOptions?.initialize
    })

    const labelOptions = tooltipOptions.label || {}
    if (labelOptions){
      tooltip.label.setAll(labelOptions)
    }

    const backgroundOptions = tooltipOptions.background || {}
    if (backgroundOptions){
    tooltip.get("background")?.setAll(backgroundOptions)
    }

    return tooltip
  }
}