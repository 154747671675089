import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["tabs", "authPassword", "privacyPassword", "snmpVersionSelect", "authProtocol", "privacyProtocol"]
  readonly tabsTargets!: HTMLElement[]
  readonly authProtocolTarget!: HTMLInputElement
  readonly authPasswordTarget!: HTMLInputElement
  readonly privacyPasswordTarget!: HTMLInputElement
  readonly privacyProtocolTarget!: HTMLInputElement
  readonly snmpVersionSelectTarget!: HTMLSelectElement

  connect() {
    this.changeSnmpVersion()
    this.changeAuthProtocol()
    this.changePrivacyProtocol()
  }

  changeSnmpVersion() {
    const value = this.snmpVersionSelectTarget.value
    this.tabsTargets.forEach((element) => {
      element.dataset.snmpVersion === value
        ? element.classList.remove("hidden")
        : element.classList.add("hidden")
    })
  }

  changeAuthProtocol() {
    const value = this.authProtocolTarget.value
    value ? this.authPasswordTarget.classList.remove("hidden") : this.authPasswordTarget.classList.add("hidden")
  }

  changePrivacyProtocol() {
    const value = this.privacyProtocolTarget.value
    value ? this.privacyPasswordTarget.classList.remove("hidden") : this.privacyPasswordTarget.classList.add("hidden")
  }
}
