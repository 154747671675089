// @ts-ignore
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["backup"]
  readonly backupTargets!: HTMLElement[]

  openConfirmation(event: Event) {
    const target = event.target as HTMLElement
    const backup = target.closest('[id^=gateway_backup_]') as HTMLElement
    const restore = document.querySelector(`#${backup.id} > div.restore-confirmation`)
    const remove = document.querySelector(`#${backup.id} > div.delete-confirmation`)

    const t = target.closest("div")
    if(t?.classList.contains("restore")){
      restore?.classList.remove("hidden")
      remove?.classList.add("hidden")
    } else {
      remove?.classList.remove("hidden")
      restore?.classList.add("hidden")
    }
  }

  removeConfirmation(event: Event) {
    event.preventDefault()
    const target = event.target as HTMLElement
    const backupConfirmation = target.closest('.container') as HTMLElement
    backupConfirmation?.classList.add("hidden")
  }
}
