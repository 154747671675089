{
  "create_account": "Create Account",
  "two_factor": {
    "enable": "Set up two-factor authentication"
  },
  "password" :{
    "custom_validation": "Passwords must be at least 12 characters",
    "confirmation_custom_validation": "Confirmation password does not match the password"
  }
}
